import { MarketingErrorResponse } from '@ecm/data/Api'

export type CouponCode = string
type Coupon_ = {
  code: CouponCode
  description: string
  amount_in_cents?: number
  percentage?: number
}

export type ApiCoupon = {
  code: CouponCode
  description: string
  amount_in_cents?: number
  percentage?: number
}
export type Coupon = Coupon_ & {
  __Type: 'Coupon'
}
export const isCoupon = (object: Coupon | MarketingErrorResponse): object is Coupon => {
  return 'description' in object
}

export const coupon = (c: Coupon_): Coupon => ({ ...c, __Type: 'Coupon' })

export const apiCoupon = (c: ApiCoupon): Coupon => ({ ...c, __Type: 'Coupon' })

export const empty = () => coupon({ code: '', description: '', percentage: 0 })

export const giveMeAFakeCouponPlease = (couponCode: string) => {
  return coupon({ code: couponCode, description: 'you are indeed the best', percentage: 30 })
}

export default {
  apiCoupon,
  coupon,
  empty,
  giveMeAFakeCouponPlease,
}
