import ManagePartner from '@ecm/capability/ManagePartner'
import { parseContentfulPartner, PartnerSlug } from '@ecm/data/Partner'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManagePartner: ManagePartner = {
  getPartner: async (slug: PartnerSlug, isPreview?: boolean) =>
    appUseContentful.getEntryBySlug('partner', slug, parseContentfulPartner, isPreview),

  getPartners: async (isPreview: boolean) =>
    appUseContentful.getEntries('partner', parseContentfulPartner, undefined, isPreview),
}

export default appManagePartner
