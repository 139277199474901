import { NextRouter } from 'next/router'

import { Maybe } from '@ecm/data/Maybe'

export type ErrorNames =
  | 'AccountValidation'
  | 'ApiError'
  | 'ApplicationError'
  | 'BillingValidation'
  | 'CartValidation'
  | 'DemoRequestError'

export const dispatchValidationError = (router: NextRouter, e: Error): void => {
  if (e.name === 'AccountValidation') {
    router.push('/checkout/account').then(() => console.log(e))
  } else if (e.name === 'BillingValidation') {
    router.push('/checkout/billing').then(() => console.log(e))
  } else if (e.name === 'CartValidation') {
    router.push('/pricing').then(() => console.log(e))
  } else {
    router.push('/pricing').then(() => console.log(e))
  }
}

export const partialValidator = <T>(p: Partial<T>, empty: T, typeName: ErrorNames): T => {
  Object.keys(empty).every(f => {
    if (!p[f as keyof T]) {
      throw createCustomError(`${f} is missing in partial`, typeName)
    }
  })
  return p as T
}

export type TypeValidator = <T>(t: Maybe<Partial<T>>) => T

export const createCustomError = (message: string, name: ErrorNames): Error => {
  const error = new Error(message)
  error.name = name
  return error
}
