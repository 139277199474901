export type Env = {
  appBaseUrl: string
  clientAppBaseUrl: string
  accountSignupExpId: string
  appCookieDomain: string
  cognitoDomain: string
  cognitoUserPoolId: string
  cognitoUserPoolMarketingClientId: string
  cognitoUserPoolWebClientId: string
  cognitoRedirectSignIn: string
  cognitoRedirectSignOut: string
  cognitoRegion: string
  contentfulAccessToken: string
  contentfulPreviewToken: string
  contentfulSpace: string
  ecmApiUrl: string
  elasticApmEnvironment: string
  elasticApmServerUrl: string
  elasticApmServiceName: string
  elasticApmServiceVersion: string
  elasticApmTracingOrigins: Array<string>
  enableEcommerceTracking: boolean
  enableContentfulPreview: boolean
  explainerVideoFileUrl: string
  facebookVerificationKey: string
  googleMapsApiKey: string
  googleTagManagerId: string
  googleOptimizeId: string
  googleVerificationKey: string
  hubSpotEmailOnlyFormId: string
  hubSpotApiUrl: string
  hubSpotBuiltFormId: string
  hubSpotNprFormId: string
  hubSpotContactFormId: string
  hubSpotDemoFormId: string
  hubSpotPortalId: string
  hubSpotLeadFormId: string
  hubSpotNewsletterSignupFormId: string
  hubSpotIndividualFormId: string
  hubSpotBusinessFormId: string
  loadCloudflareImage: boolean
  mobileAppsVideoUrl: string
  oneTrustId: string
  sentryDSN: string
  shareASaleMerchantId: string
}

export const env: Env = {
  accountSignupExpId: process.env.NEXT_PUBLIC_ACCOUNT_EXP_ID ?? '1AbMqS7URlCwnLhnxmCgqA',
  appBaseUrl: process.env.NEXT_PUBLIC_APP_BASE_URL || 'https://www.earthclassmail.com',
  appCookieDomain: process.env.NEXT_PUBLIC_APP_COOKIE_DOMAIN ?? 'localhost',
  clientAppBaseUrl: process.env.NEXT_PUBLIC_CLIENT_APP_BASE_URL ?? 'https://app.earthclassmail.com',
  cognitoDomain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN ?? 'auth-dev.earthclassmail.com',
  cognitoRedirectSignIn:
    process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN ?? 'http://localhost:3000/checkout/billing',
  cognitoRedirectSignOut:
    process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT ?? 'http://localhost:3000/checkout/account',
  cognitoRegion: process.env.NEXT_PUBLIC_COGNITO_REGION ?? 'us-west-2',
  cognitoUserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ?? 'us-west-2_1LzH5vC4j',
  cognitoUserPoolMarketingClientId:
    process.env.NEXT_PUBLIC_COGNITO_USER_POOL_MARKETING_CLIENT_ID ?? '7a3gtghbgj1rng0guvqmp6bgoq',
  cognitoUserPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT_ID ?? '',
  contentfulAccessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || '',
  contentfulPreviewToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN || '',
  contentfulSpace: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || '',
  ecmApiUrl: process.env.NEXT_PUBLIC_ECM_API_URL || 'https://devapi.earthclassmail.com/dev',
  elasticApmEnvironment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT || 'development',
  elasticApmServerUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL || '',
  elasticApmServiceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME || 'marketing',
  elasticApmServiceVersion: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_VERSION || '',
  elasticApmTracingOrigins:
    (process.env.NEXT_PUBLIC_ELASTIC_APM_TRACING_ORIGINS &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_TRACING_ORIGINS.split(',')) ||
    [],
  enableContentfulPreview: process.env.NEXT_PUBLIC_ENABLE_PREVIEW === 'true',
  enableEcommerceTracking: process.env.NEXT_PUBLIC_ENABLE_ECOMMERCE_TRACKING === 'true',
  explainerVideoFileUrl: process.env.NEXT_PUBLIC_EXPLAINER_VIDEO_FILE_URL || '',
  facebookVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION_KEY || '',
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  googleOptimizeId: process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE || '',
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
  googleVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_KEY || '',
  hubSpotApiUrl:
    process.env.NEXT_PUBLIC_HUBSPOT_API_URL ||
    'https://api.hsforms.com/submissions/v3/integration/',
  hubSpotBuiltFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_BUILT_FORM_ID || '8e0323ce-b3c3-4040-890e-677b31eeebc9',
  hubSpotBusinessFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_BUSINESS_FORM_ID || 'b912665c-2cb5-493e-b8f1-842a91a71baa',
  hubSpotContactFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_CONTACT_FORM_ID || '8ef6a303-46c6-4f31-be92-1400450c0979',
  hubSpotDemoFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_DEMO_FORM_ID || '93cb5559-a584-42db-b0c0-68a2e3bd929a',
  hubSpotEmailOnlyFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_EMAIL_ONLY_ID || '41d380b5-2d6a-47e3-af24-d5ef487d7671',
  hubSpotIndividualFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_INDIVIDUAL_FORM_ID || 'b219ad56-d084-4206-a7b3-0feb48e5bf68',
  hubSpotLeadFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_LEAD_FORM_ID || '92e9c9c0-3c16-415c-ab46-224e447f9a97',
  hubSpotNewsletterSignupFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_SIGNUP_FORM_ID ||
    'b7ab0a37-1c11-4af3-8927-738e47b878f4',
  hubSpotNprFormId:
    process.env.NEXT_PUBLIC_HUBSPOT_NPR_FORM_ID || 'f5c75085-f0c0-4067-985f-2eac1f2b5dc0',
  hubSpotPortalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID || '1597890',
  loadCloudflareImage: process.env.NEXT_PUBLIC_LOAD_CLOUDFLARE_IMAGE === 'true',
  mobileAppsVideoUrl: process.env.NEXT_PUBLIC_MOBILE_APPS_VIDEO_URL || '',
  oneTrustId: process.env.NEXT_PUBLIC_ONE_TRUST_ID ?? 'e95ef060-b45c-47db-88ef-4c9cb5e317eb',
  sentryDSN:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://e39c2e317b3f40fcb95cf86d6989780a@o23208.ingest.sentry.io/5462509',
  shareASaleMerchantId: process.env.NEXT_PUBLIC_SHAREASALE_MERCHANT_ID || '13778',
}

export default env
