import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful'

import { FaqCategory, parseContentfulFaqCategory } from '@ecm/data/FaqCategory'

type Faq_ = {
  answer: Document
  categories: FaqCategory[]
  question: string
  page: string[]
}

export type Faq = Faq_ & {
  __Type: 'faq'
}

export const faq = (faq_: Faq_): Faq => ({
  ...faq_,
  __Type: 'faq',
})

export type ContentfulFaq = {
  categories: string[]
  question: string
  page: string[]
  richTextAnswer: Document
}

export const parseContentfulFaq = (e: Entry<ContentfulFaq>): Faq => {
  const { categories, question, page, richTextAnswer } = e.fields

  return faq({
    answer: richTextAnswer,
    categories: categories?.map(parseContentfulFaqCategory) ?? [],
    page,
    question,
  })
}

export default { parseContentful: parseContentfulFaq }
