import ManageAddress from '@ecm/capability/ManageAddress'
import Address, { AddressSlug } from '@ecm/data/Address'
import { ContentQuery } from '@ecm/data/Content'
import { parseContentfulEntryMetadata } from '@ecm/data/EntryMetadata'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageAddress: ManageAddress = {
  getAddress: async (slug: AddressSlug) =>
    appUseContentful.getEntryBySlug('address', slug, Address.parseContentful),
  getAddresses: async (query?: ContentQuery) =>
    appUseContentful.getEntries('address', Address.parseContentful, query),
  getAddressesMetadata: async () =>
    appUseContentful.getEntryMetadata('address', parseContentfulEntryMetadata),
  getPopularAddresses: async () =>
    appUseContentful.getEntriesByFieldValues(
      'address',
      'addressCategories',
      ['popular'],
      Address.parseContentful
    ),
}

export default appManageAddress
