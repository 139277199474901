import ManageAnnouncement from '@ecm/capability/ManageAnnouncement'
import { AnnouncementSlug, parseContentfulAnnouncement } from '@ecm/data/Announcement'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageAnnouncement: ManageAnnouncement = {
  getAnnouncement: async (slug: AnnouncementSlug) =>
    appUseContentful.getEntryBySlug('announcement', slug, parseContentfulAnnouncement),
}

export default appManageAnnouncement
