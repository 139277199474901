import { Asset, Entry } from 'contentful'

export type ArticleStep_ = {
  title: string
  text: string
  image?: string | null
}

export type ArticleStep = ArticleStep_ & {
  __Type: 'articleStep'
}

export const articleStep = (step_: ArticleStep_): ArticleStep => ({
  ...step_,
  __Type: 'articleStep',
})

export type ContentfulArticleStep = {
  title: string
  text: string
  image: Asset
}

export const parseContentfulArticleStep = (e: Entry<ContentfulArticleStep>): ArticleStep => {
  const { title, text, image } = e.fields

  return articleStep({
    image: image ? image.fields.file.url : null,
    text,
    title,
  })
}

export default { parseContentful: parseContentfulArticleStep }
