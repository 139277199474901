import { ManageLead } from '@ecm/capability/ManageLead'
import { HttpResponse } from '@ecm/data/Api'
import { HubSpotResponse } from '@ecm/data/HubSpot'
import { Lead } from '@ecm/data/Lead'
import appUseHubSpot from '@ecm/effect/app/UseHubSpot'

export const appManageLead: ManageLead = {
  submitGenericLead: async (
    lead: Lead,
    formId: string,
    source?: string
  ): Promise<HubSpotResponse> => {
    const hubSpotLead: HttpResponse<HubSpotResponse> = await appUseHubSpot.submitGenericLeadForm(
      lead,
      formId,
      source
    )
    if (hubSpotLead.parsedBody === undefined) {
      return { errors: [{ errorType: 'connection', message: 'failed to reach Hubspot' }] }
    }

    return hubSpotLead.parsedBody
  },

  submitMainSignUpLead: async (lead: Lead): Promise<HubSpotResponse> => {
    const hubSpotLead: HttpResponse<HubSpotResponse> = await appUseHubSpot.submitLeadForm(lead)
    if (hubSpotLead.parsedBody === undefined) {
      return { errors: [{ errorType: 'connection', message: 'failed to reach Hubspot' }] }
    }

    return hubSpotLead.parsedBody
  },
}
export default appManageLead
