import { ReactNode, useEffect, useState } from 'react'
import * as React from 'react'

import { activeFeatureToggles } from '@ecm/data/FeatureToggle'
import { runClientEff } from '@ecm/effect'

export type FeatureToggleType = {
  children: ReactNode
  featureName: activeFeatureToggles
  elementId?: string
}

// while using Optimize we want to set the visibility to hidden and use optimize to turn it on for flicker reasons
export const AbTestToggle = (props: FeatureToggleType) => {
  const [testEnabled, setTestEnabled] = useState<boolean>(false)

  useEffect(() => {
    runClientEff(eff => {
      setTestEnabled(eff.ask().featureToggle[props.featureName])
    })
  })

  return (
    <>
      {testEnabled ? (
        <div id={props.elementId} style={{ display: 'none' }}>
          {props.children}
        </div>
      ) : undefined}
    </>
  )
}

export const FeatureToggle = (props: FeatureToggleType) => {
  const featureEnabled = runClientEff(eff => {
    return eff.ask().featureToggle[props.featureName]
  })

  return <>{featureEnabled && props.children}</>
}
