import { Entry } from 'contentful'

export type _Addon = {
  apiAddonKey: string
  label?: string
  price: number
  quantity?: number
  canHaveMultiple: boolean
  slug: string
}

type contentfulAddon = {
  apiAddonKey: string
  label: string
  price: string
  slug: string
  canHaveMultiple: boolean
}
export type Addon = _Addon & {
  __Type: 'Addon'
}

export const empty = (): Addon => {
  return addon({
    apiAddonKey: '',
    canHaveMultiple: false,
    label: '',
    price: 0,
    slug: '',
  })
}
export const addon = (a_: _Addon): Addon => ({
  ...a_,
  __Type: 'Addon',
})

export const parseContentfulAddon = (e: Entry<contentfulAddon>): Addon => {
  const fields = e.fields
  return addon({
    apiAddonKey: fields.apiAddonKey,
    canHaveMultiple: fields.canHaveMultiple,
    label: fields.label,
    price: Number(fields.price),
    slug: fields.slug,
  })
}
export const hasCheckDeposit = (addons: Addon[]) => {
  return addons.some((a: Addon) => a.slug === 'checkDeposit')
}

export default {
  addon,
  empty,
  hasCheckDeposit,
  parseContentfulAddon,
}
