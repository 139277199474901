import ManageCaseStudy from '@ecm/capability/ManageCaseStudy'
import { parseCaseStudy } from '@ecm/data/CaseStudy'
import appUseContentful from '@ecm/effect/app/UseContentful'

const appManageCaseStudy: ManageCaseStudy = {
  getCaseStudies: async () => appUseContentful.getEntries('caseStudies', parseCaseStudy),
  getCaseStudyBySlug: (slug: string) =>
    appUseContentful.getEntryBySlug('caseStudies', slug, parseCaseStudy),
}

export default appManageCaseStudy
