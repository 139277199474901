import { join, path } from 'ramda'

import ManageDemo from '@ecm/capability/ManageDemo'
import { Demo, RequestDemoResponse } from '@ecm/data/Demo'
import appUseHubSpot from '@ecm/effect/app/UseHubSpot'

export const appManageDemo: ManageDemo = {
  createDemo: async (demo: Demo): Promise<RequestDemoResponse> => {
    const { parsedBody } = await appUseHubSpot.createDemo(demo)

    const response =
      parsedBody !== undefined
        ? parsedBody
        : {
            errors: [
              {
                errorType: ['connection'],
                message: 'failed to reach HubSpot',
              },
            ],
          }

    const status: 'success' | 'error' = response.errors ? 'error' : 'success'

    const message: string | undefined = response.errors
      ? join(', ', path(['errors'], response) || [])
      : path(['inlineMessage'], response)

    return {
      message,
      status,
    }
  },
}

export default appManageDemo
