import { createTheme, Theme } from '@mui/material/styles'

import Color from '@ecm/ui/element/Color'

let theme: Theme = createTheme()

theme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        variant: 'text',
      },
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            color: Color.saint.darkest,
            padding: 0,
            textTransform: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: Color.saint.normal,
        fontWeight: '700',
        underline: 'none',
        variant: 'p3',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Mulish", sans-serif',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          overline: 'p',
          p1: 'p',
          p2: 'p',
          p3: 'p',
          p4: 'p',
        },
      },
    },
  },
  typography: {
    button: {
      fontFamily: '"Mulish", sans-serif',
      fontSize: '14px',
      lineHeight: '22px',
    },
    fontFamily: '"Mulish", sans-serif',
    h1: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '76px',
      fontWeight: '700',
      letterSpacing: '0',
      lineHeight: '104px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '60px',
        lineHeight: '84px',
      },
    },
    h2: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '60px',
      fontWeight: '700',
      letterSpacing: '0',
      lineHeight: '84px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '42px',
        lineHeight: '56px',
      },
    },
    h3: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '42px',
      fontWeight: '700',
      letterSpacing: '0',
      lineHeight: '56px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '44px',
      },
    },
    h4: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '32px',
      fontWeight: '700',
      letterSpacing: '0',
      lineHeight: '44px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    h5: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '24px',
      fontWeight: '700',
      letterSpacing: '0',
      lineHeight: '36px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    h6: {
      color: Color.saint.darkest,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '30px',
      margin: '0 0 0.5em',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    overline: {
      color: Color.slate.normal,
      fontFamily: '"Mulish", sans-serif',
      fontSize: '14px',
      fontWeight: '700',
      letterSpacing: '0.15em',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
    p1: {
      color: Color.saint.darkest,
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '34px',
      margin: '0 0 1em',
    },
    p2: {
      color: Color.saint.darkest,
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '30px',
      margin: '0 0 1em',
    },
    p3: {
      color: Color.saint.darkest,
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      margin: '0 0 1em',
    },
    p4: {
      color: Color.saint.darkest,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '22px',
      margin: '0 0 1em',
    },
  },
})

export default theme
