import { partialValidator } from '@ecm/data/Validation'

export type Account_ = {
  email: string
  firstName: string
  lastName: string
  phoneNumber?: number
  company?: string
  externalSub?: string
}
export type Account = Account_ & {
  __Type: 'Account'
  displayFullName?: string
}

export const account = (a_: Account_): Account => ({
  ...a_,
  __Type: 'Account',
  displayFullName: a_.firstName || a_.lastName ? `${a_.firstName} ${a_.lastName}` : undefined,
})

export const empty = (): Account =>
  account({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: 0,
  })

const isValidAccount = (pAccount: Partial<Account>): boolean => {
  try {
    const fullAccount = partialValidator<Account>(pAccount, empty(), 'AccountValidation')
    if (!fullAccount.email) {
      return false
    }
    if (!fullAccount.externalSub && !fullAccount.firstName) {
      return false
    }
    if (!fullAccount.externalSub && !fullAccount.lastName) {
      return false
    }
  } catch {
    return false
  }

  return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapAccountFromCognito = (data: any): Account => {
  const payload = data.signInUserSession.idToken.payload
  return account({
    email: payload.email,
    externalSub: payload.sub,
    firstName: payload.given_name,
    lastName: payload.family_name,
  })
}

export default {
  account,
  empty,
  isValidAccount,
  mapAccountFromCognito,
}
