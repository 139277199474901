import { Entry } from 'contentful'

export type PlanHandle =
  | 'business_address'
  | 'lz-mailbox-a'
  | 'lz-mailbox-b'
  | 'mailbox-c'
  | 'high-volume-a'
  | 'high-volume-b'

export type PlanLabel =
  | 'Business Address'
  | 'Starter MailBox'
  | 'Essentials MailBox'
  | 'Premium MailBox'
  | 'Startup MailRoom'
  | 'Business MailRoom'

export type PlanHandleToLabel = { [k in PlanHandle]: PlanLabel }

export const planHandleToLabelMap: PlanHandleToLabel = {
  /* eslint-disable sort-keys */
  business_address: 'Business Address',
  'lz-mailbox-a': 'Starter MailBox',
  'lz-mailbox-b': 'Essentials MailBox',
  'mailbox-c': 'Premium MailBox',
  'high-volume-a': 'Startup MailRoom',
  'high-volume-b': 'Business MailRoom',
  /* eslint-disable sort-keys */
}

export type Plan_ = {
  handle: string
  internalValue: string
  name: string
  label: string
  price: number
  recipientCount?: number
  mailCount?: number
  restrictedAddress?: boolean
  businessType: PlanTypes
}

export type Plan = Plan_ & {
  __Type: 'plan'
}

export const plan = (p_: Plan_): Plan => ({
  ...p_,
  __Type: 'plan',
})

export type PlanTypes = 'highVolume' | 'mailBox' | 'other' | ''

export type ContentfulPlan = {
  description: string
  internalValue: string
  mailCount: number
  name: string
  price: number
  recipientCount?: number
  restrictedAddress?: boolean
  slug: string
  type: PlanTypes
}

export const parseContentfulPlan = (e: Entry<ContentfulPlan>): Plan => {
  const fields = e.fields
  return plan({
    businessType: fields.type,
    handle: fields.slug,
    internalValue: fields.internalValue,
    label: fields.description,
    mailCount: fields.mailCount,
    name: fields.name,
    price: fields.price,
    recipientCount: fields.recipientCount || 0,
    restrictedAddress: fields.restrictedAddress,
  })
}
export const empty = (): Plan =>
  plan({
    businessType: '',
    handle: '',
    internalValue: '',
    label: '',
    mailCount: 0,
    name: '',
    price: 0,
    recipientCount: 0,
    restrictedAddress: false,
  })

export default { empty, parseContentfulPlan }
