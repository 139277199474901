import ManageTestimonial from '@ecm/capability/ManageTestimonial'
import { parseContentfulTestimonial, TestimonialSlug } from '@ecm/data/Testimonial'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageTestimonial: ManageTestimonial = {
  getTestimonial: async (slug: TestimonialSlug, isPreview?: boolean) =>
    appUseContentful.getEntryBySlug('testimonial', slug, parseContentfulTestimonial, isPreview),

  getTestimonials: async () =>
    appUseContentful.getEntries('testimonial', parseContentfulTestimonial),

  getTestimonialsBySlug: async (slugs: string[], isPreview?: boolean) =>
    appUseContentful.getEntriesByFieldValues(
      'testimonial',
      'slug',
      slugs,
      parseContentfulTestimonial,
      '[in]',
      undefined,
      isPreview
    ),
}

export default appManageTestimonial
