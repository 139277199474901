import { Entry } from 'contentful'

type CouponCode_ = {
  name: string
  code: string
}

export type CouponCode = CouponCode_ & { __Type: 'couponCode' }

const couponCode = (cc_: CouponCode_): CouponCode => ({
  ...cc_,
  __Type: 'couponCode',
})

type ContentfulCouponCode = {
  name: string
  code: string
}

export const parseContentfulCopuponCode = (e: Entry<ContentfulCouponCode>): CouponCode => {
  const { name, code } = e.fields
  return couponCode({ code, name })
}
