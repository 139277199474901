import ManageArticle from '@ecm/capability/ManageArticle'
import { Settings } from '@ecm/config/Settings'
import { parseContentfulArticle, parseContentfulPaginatedCollection } from '@ecm/data/Article'
import { ContentQuery } from '@ecm/data/Content'
import { parseContentfulEntryMetadata } from '@ecm/data/EntryMetadata'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageArticle: ManageArticle = {
  getArticle: async (slug: string, isPreview?: boolean) =>
    appUseContentful.getEntryBySlug('article', slug, parseContentfulArticle, isPreview),

  getArticles: async (query?: ContentQuery) =>
    appUseContentful.getEntries('article', parseContentfulArticle, query),

  getArticlesByCategory: async (catId: string, query?: ContentQuery) =>
    appUseContentful.getEntriesByLinkedEntry('article', catId, parseContentfulArticle, query),

  getArticlesByDate: async (date: string, query?: ContentQuery) =>
    appUseContentful.getEntriesByFieldValues(
      'article',
      'publishedDate',
      [date],
      parseContentfulArticle,
      '[gte]',
      query
    ),

  getArticlesMetadata: async () =>
    appUseContentful.getEntryMetadata('article', parseContentfulEntryMetadata),

  getArticlesMetadataByFieldValue: async (field: string) =>
    appUseContentful.getEntryMetadataByFieldValue('article', field, parseContentfulEntryMetadata),

  getArticlesMetadataById: async (id: string) =>
    appUseContentful.getEntryMetadataById('article', id, parseContentfulEntryMetadata),

  getPaginatedArticles: async page => {
    const skipMultiplier = page === 1 ? 0 : page - 1
    const skip = skipMultiplier > 0 ? Settings.pagination.pageSize * skipMultiplier : 0

    const paginatedEntries = await appUseContentful.getPaginatedEntries(
      'article',
      parseContentfulPaginatedCollection,
      {
        limit: Settings.pagination.pageSize,
        order: '-fields.publishedDate',
        skip: skip,
      }
    )

    if (!paginatedEntries) {
      return { items: [], total: 0 }
    }
    return { items: paginatedEntries.items, total: paginatedEntries.total }
  },

  getPaginatedArticlesById: async (id: string, page: number) => {
    const skipMultiplier = page === 1 ? 0 : page - 1
    const skip = skipMultiplier > 0 ? Settings.pagination.pageSize * skipMultiplier : 0

    const paginatedEntries = await appUseContentful.getPaginatedEntriesByLinkedEntry(
      'article',
      id,
      parseContentfulPaginatedCollection,
      {
        limit: Settings.pagination.pageSize,
        order: '-fields.publishedDate',
        skip: skip,
      }
    )

    if (!paginatedEntries) {
      return { items: [], total: 0 }
    }
    return { items: paginatedEntries.items, total: paginatedEntries.total }
  },
  getRelatedArticles: async (field: string, catId?: string, query?: ContentQuery) =>
    appUseContentful.getEntriesByLinkedEntryWithField(
      'article',
      field,
      parseContentfulArticle,
      catId,
      query
    ),
}

export default appManageArticle
