import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful'

export type TestimonialSlug = string

export const testimonialSlug = (s: string): TestimonialSlug => s

type Testimonial_ = {
  description: string
  fullName: string
  logo?: string
  photo?: string
  quote: Document
  slug: TestimonialSlug
}

export type Testimonial = Testimonial_ & { __Type: 'testimonial' }

export const testimonial = (t_: Testimonial_): Testimonial => ({
  ...t_,
  __Type: 'testimonial',
  slug: testimonialSlug(t_.slug),
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ContentfulTestimonial = {
  description: string
  fullName: string
  logo?: Entry<any>
  photo?: Entry<any>
  quote: Document
  slug: string
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const parseContentfulTestimonial = (ct: Entry<ContentfulTestimonial>): Testimonial => {
  const { description, fullName, logo, photo, quote, slug } = ct.fields

  return testimonial({
    description,
    fullName,
    logo: logo ? logo.fields.file.url : null,
    photo: photo ? photo.fields.file.url : null,
    quote,
    slug,
  })
}

export default { parseContentful: parseContentfulTestimonial }
