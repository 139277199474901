import { Amplify } from 'aws-amplify'

import { runEff } from '.'

export const initAmplify = () => {
  runEff(eff => {
    const config = eff.ask().amplify
    const awsConfig = {
      cookieStorage: {
        domain: config.appCookieDomain,
        expires: 365,
        path: '/',
        secure: process.env.NODE_ENV !== 'development',
      },
      oauth: {
        domain: config.cognitoDomain,
        redirectSignIn: config.cognitoRedirectSignIn,
        redirectSignOut: config.cognitoRedirectSignOut,
        responseType: 'code',
        scope: ['aws.cognito.signin.user.admin', 'email', 'profile', 'openid'],
      },
      region: config.cognitoRegion,
      userPoolId: config.cognitoUserPoolId,
      userPoolWebClientId: config.cognitoUserPoolMarketingClientId,
    }

    Amplify.configure(awsConfig)
  })
}
