export type Carrier = 'dhl' | 'fedex' | 'ups' | 'usps'

export const parseContentfulCarrier = (s: string): Carrier => {
  if (s === 'DHL') {
    return 'dhl'
  } else if (s === 'FedEx') {
    return 'fedex'
  } else if (s === 'UPS') {
    return 'ups'
  } else {
    return 'usps'
  }
}

export const all: Carrier[] = ['dhl', 'fedex', 'ups', 'usps']

export default { all }
