import { Entry } from 'contentful'

type ArticleFaq_ = {
  answer: string
  question: string
}

export type ArticleFaq = ArticleFaq_ & {
  __Type: 'articleFaq'
}

export const articleFaq = (faq_: ArticleFaq_): ArticleFaq => ({
  ...faq_,
  __Type: 'articleFaq',
})

export type ContentfulArticleFaq = {
  question: string
  answer: string
}

export const parseContentfulArticleFaq = (e: Entry<ContentfulArticleFaq>): ArticleFaq => {
  const { question, answer } = e.fields

  return articleFaq({
    answer,
    question,
  })
}

export default { parseContentful: parseContentfulArticleFaq }
