import ManageCategories from '@ecm/capability/ManageCategories'
import { parseContentfulArticleCategory } from '@ecm/data/ArticleCategory'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageCategories: ManageCategories = {
  getCategories: async () =>
    appUseContentful.getEntries('articleCategory', parseContentfulArticleCategory),
}

export default appManageCategories
