import { Link as MuiLink, styled } from '@mui/material'
import Link from 'next/link'

import Color from '@ecm/ui/element/Color'

type AnchorProps = {
  className?: string
  caret?: boolean
  children: React.ReactNode
  external?: boolean
  href: string
  prefetch?: boolean
  scroll?: boolean
  whiteCaret?: boolean
  gray?: boolean
  disabled?: boolean
} & React.ComponentProps<typeof MuiLink>

const Anchor = styled(
  ({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    caret = false,
    scroll = true,
    children,
    external,
    prefetch,
    href,
    whiteCaret,
    gray,
    disabled,
    ...otherProps
  }: /* eslint-enable @typescript-eslint/no-unused-vars */
  AnchorProps) => {
    if (external) {
      return (
        <MuiLink
          href={href}
          margin="0"
          display="inline-block"
          target="_blank"
          rel="noreferrer nofollow"
          onClick={e => e.stopPropagation()}
          {...otherProps}
        >
          {children}
        </MuiLink>
      )
    }

    return (
      <Link href={href} prefetch={prefetch} scroll={scroll} passHref>
        <MuiLink
          margin="0"
          display="inline-block"
          onClick={e => e.stopPropagation()}
          {...otherProps}
        >
          {children}
        </MuiLink>
      </Link>
    )
  }
)(({ caret, disabled, gray, whiteCaret }: AnchorProps) => ({
  ...(caret || whiteCaret
    ? {
        '&:after': {
          background: `url('/images/icon/caret-${
            whiteCaret ? 'white' : 'right'
          }.svg') no-repeat center`,
          backgroundSize: '10px 40px',
          content: "''",
          height: '100%',
          position: 'absolute',
          right: '10px',
          top: 0,
          transition: '200ms right ease-out',
          width: '10px',
        },
        '&:hover': {
          '&:after': {
            right: 0,
          },
        },
        padding: '10px 30px 10px 0',
        position: 'relative',
      }
    : {}),
  ...(gray
    ? {
        '&:hover': {
          color: Color.mono.lighter,
        },
        color: Color.mono.normal,
        transition: 'color 200ms ease-out',
      }
    : {}),
  ...(disabled
    ? {
        color: Color.saint.lightest,
        pointerEvents: 'none',
      }
    : {}),
}))

export default Anchor
