import ManageFaq from '@ecm/capability/ManageFaq'
import { parseContentfulFaq } from '@ecm/data/Faq'
import { FaqCategory, toContentfulFaqCategory } from '@ecm/data/FaqCategory'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageFaq: ManageFaq = {
  getFaqsByCategory: async (cats: FaqCategory[]) =>
    appUseContentful.getEntriesByFieldValues(
      'faq',
      'categories',
      cats.map(toContentfulFaqCategory),
      parseContentfulFaq
    ),
  getFaqsByPage: async (page: string[]) =>
    appUseContentful.getEntriesByFieldValues('faq', 'page', page, parseContentfulFaq),
}

export default appManageFaq
