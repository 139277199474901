export type DropdownContextType = {
  isDropdownExpanded: boolean
  handleDropdownChange: (value?: boolean) => void
  pickedCategory?: string
  handleCategoryChange: (category?: string) => void
}

export const initialDropdownContext = {
  handleCategoryChange: () => undefined,
  handleDropdownChange: () => undefined,
  isDropdownExpanded: false,
  pickedCategory: '',
}
