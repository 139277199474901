type Location_ = {
  lat: number
  lng: number
}

export type Location = Location_ & { __Type: 'location' }

export const location = (l_: Location_): Location => ({
  ...l_,
  __Type: 'location',
})

export type ContentfulLocation = {
  lat: number
  lon: number
}

export const parseContentfulLocation = (e: ContentfulLocation): Location => {
  return location({
    lat: e.lat,
    lng: e.lon,
  })
}

function rad2degr(rad: number) {
  return (rad * 180) / Math.PI
}
function degr2rad(degree: number) {
  return (degree * Math.PI) / 180
}

/**
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 * @param latLngInDegr
 * https://stackoverflow.com/questions/6671183/calculate-the-center-point-of-multiple-latitude-longitude-coordinate-pairs
 */
export const getLatLngCenter = (latLngInDegr: number[][]) => {
  const LATIDX = 0
  const LNGIDX = 1
  let sumX = 0
  let sumY = 0
  let sumZ = 0

  for (let i = 0; i < latLngInDegr.length; i++) {
    const lat = degr2rad(latLngInDegr[i][LATIDX])
    const lng = degr2rad(latLngInDegr[i][LNGIDX])
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng)
    sumY += Math.cos(lat) * Math.sin(lng)
    sumZ += Math.sin(lat)
  }

  const avgX = sumX / latLngInDegr.length
  const avgY = sumY / latLngInDegr.length
  const avgZ = sumZ / latLngInDegr.length

  // convert average x, y, z coordinate to latitude and longtitude
  const lng = Math.atan2(avgY, avgX)
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY)
  const lat = Math.atan2(avgZ, hyp)

  return [rad2degr(lat), rad2degr(lng)]
}

export default { parseContentful: parseContentfulLocation }
