import { just, Maybe, nothing } from '@ecm/data/Maybe'

export const deleteCookie = (name: string, domain?: string) => {
  const expires = new Date()
  expires.setTime(expires.getTime() - 1 * 24 * 60 * 60 * 1000)
  let cookie = name + '=; expires=' + expires.toUTCString() + '; path=/'
  if (domain) {
    cookie += `; domain=${domain}`
  }
  document.cookie = cookie
}

export const getCookie = (name: string): Maybe<string> => {
  const cookie = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.substring(0, name.length + 1) === `${name}=`)
    .find(c => decodeURIComponent(c.substring(name.length + 1)))

  return cookie ? just(cookie.slice(name.length + 1)) : nothing
}

export const findCookies = (value: string): Maybe<string[]> => {
  const cookies = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith(value))
  return cookies ? just(cookies) : nothing
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const setCookie = (
  name: string,
  value: any,
  expiry?: number,
  domain?: string,
  secure?: boolean
): void => {
  const expires = new Date()
  expires.setTime(expires.getTime() + (expiry ?? 7 * 24 * 60 * 60 * 1000))
  let cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`
  if (domain) {
    cookie += `; domain=${domain}`
  }
  if (secure) {
    cookie += '; secure'
  }
  document.cookie = cookie
}

export default {
  delete: deleteCookie,
  find: findCookies,
  get: getCookie,
  set: setCookie,
}
