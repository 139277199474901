// Maybe

export type Just<A> = {
  value: A
  toString: () => string
  __Type: 'Just'
}

export type Nothing = {
  toString: () => string
  __Type: 'Nothing'
}

export type Maybe<A> = Just<A> | Nothing

// Constructors

export const just = <A>(a: A): Maybe<A> => ({
  __Type: 'Just',
  toString: () => 'Just ' + a,
  value: a,
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export const nothing: Nothing = {
  __Type: 'Nothing',
  toString: () => 'Nothing',
}

export const maybe = <A>(a: A): Maybe<A> => (a === undefined || a === null ? just(a) : nothing)

export const isJust = <A>(ma: Maybe<A>): boolean => ma.__Type === 'Just'
export const isNothing = <A>(ma: Maybe<A>): boolean => ma.__Type === 'Nothing'

// Functions

export const getOrElse = <A>(ma: Maybe<A>, defaultValue: A): A => {
  switch (ma.__Type) {
    case 'Just':
      return ma.value
    default:
      return defaultValue
  }
}

export const unSafeGet = <A>(ma: Maybe<A>, error?: Error): A => {
  switch (ma.__Type) {
    case 'Just':
      return ma.value
    default:
      throw error || new Error('Maybe does not exist')
  }
}

export const mapMaybe = <A, B>(ma: Maybe<A>, f: (a: A) => B): Maybe<B> => {
  switch (ma.__Type) {
    case 'Just':
      return just(f(ma.value))
    default:
      return nothing
  }
}

export const toPromise = <A>(ma: Maybe<A>): Promise<A> => {
  switch (ma.__Type) {
    case 'Just':
      return Promise.resolve(ma.value)
    default:
      return Promise.reject()
  }
}

export default {
  from: maybe,
  getOrElse: getOrElse,
  isJust: isJust,
  isNothing: isNothing,
  map: mapMaybe,
  toPromise: toPromise,
}
