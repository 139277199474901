export type UserAuth = {
  userName?: string
}

export type UserAuthContextType = {
  user?: UserAuth
  handleCurrentAuthenticatedUser: (verifyIrisAccount?: boolean, syncCart?: boolean) => void
}

export const initialUserAuthContext: UserAuthContextType = {
  handleCurrentAuthenticatedUser: () => undefined,
  user: undefined,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapUserAuthInfo = (data: any): UserAuth | undefined =>
  data.signInUserSession || data.username
    ? {
        userName: data.signInUserSession
          ? data.signInUserSession.idToken.payload.email
          : data.username,
      }
    : undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapUserAuthToken = (data: any): string =>
  data.signInUserSession ? data.signInUserSession.idToken.jwtToken : ''
