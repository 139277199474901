import React, { createContext, useEffect } from 'react'
import { apmBase } from '@elastic/apm-rum'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { Read } from '@ecm/capability/Read'
import { AnnouncementContextType, initialAnnouncementContext } from '@ecm/data/Announcement'
import { DropdownContextType, initialDropdownContext } from '@ecm/data/Dropdown'
import { initialUserAuthContext, UserAuthContextType } from '@ecm/data/UserAuth'
import { runClientEff } from '@ecm/effect'
import { initAmplify } from '@ecm/effect/Amplify'
import { FeatureToggle } from '@ecm/ui/component/FeatureToggle'
import useCategoryDropdown from '@ecm/ui/hook/UseCategoryDropdown'
import useUserAuthInfo from '@ecm/ui/hook/UseUserAuthInfo'
import { handleCognitoPreSignupErrors } from '@ecm/utils/auth'
import createEmotionCache from '@ecm/utils/createEmotionCache'
import theme from '@ecm/utils/theme'

import '@ecm/ui/style/app.scss'
import '@ecm/ui/style/typography.scss'

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const HubSpotConversations: any
/* eslint-enable @typescript-eslint/no-explicit-any */

export const AnnouncementContext = createContext<AnnouncementContextType>(
  initialAnnouncementContext
)

export const DropdownContext = createContext<DropdownContextType>(initialDropdownContext)

export const UserAuthInfoContext = createContext<UserAuthContextType>(initialUserAuthContext)

const clientSideEmotionCache = createEmotionCache()

/* eslint-disable @typescript-eslint/no-explicit-any */
export const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: {
  Component: any
  emotionCache: any
  pageProps: any
}) => {
  const router = useRouter()

  const { handleCurrentAuthenticatedUser, userInfo } = useUserAuthInfo()

  const { handleCategoryChange, handleDropdownChange, isDropdownExpanded, pickedCategory } =
    useCategoryDropdown()

  const { gtmId, optimizeId, oneTrustId } = runClientEff(
    (eff: Read<{ googleTagManagerId: string; googleOptimizeId: string; oneTrustId: string }>) => {
      return {
        gtmId: eff.ask().googleTagManagerId,
        oneTrustId: eff.ask().oneTrustId,
        optimizeId: eff.ask().googleOptimizeId,
      }
    }
  )

  useEffect(() => {
    runClientEff(eff => {
      handleCognitoPreSignupErrors(router)
      initAmplify()

      eff.trackGa({ event: 'optimize.activate' })

      const elasticApmConfig = eff.ask().elasticApm
      if (elasticApmConfig.serverUrl) {
        apmBase.init({
          breakdownMetrics: true,
          distributedTracingOrigins: elasticApmConfig.tracingOrigins,
          environment: elasticApmConfig.environment,
          serverUrl: elasticApmConfig.serverUrl,
          serviceName: elasticApmConfig.serviceName,
          serviceVersion: elasticApmConfig.serviceVersion,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onRouteChangeStart = (path: string) => {
      runClientEff(() => {
        apmBase.startTransaction(path, 'route-change', { managed: true })
      })
    }

    const onRouteChangeComplete = () => {
      if (!['/', '/privacy', '/partner/stripe-atlas'].includes(router.pathname)) {
        router.reload()
      }
      runClientEff(eff => {
        eff.trackGa({ event: 'optimize.activate' })

        if (eff.ask().sentryDsn) {
          Sentry.init({
            dsn: eff.ask().sentryDsn,
            //enabled : process.env.NODE_ENV === "production",
            enabled: true,
            ignoreErrors: [
              'TypeError: Failed to fetch',
              'TypeError: NetworkError when attempting to fetch resource.',
              'TypeError: cancelled',
            ],
            release: 'my-project-name@' + process.env.npm_package_version,
          })
        }
      })
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router, router.events, router.pathname])

  return (
    <>
      <Script
        async
        src={`https://www.googleoptimize.com/optimize.js?id=${optimizeId}`}
        strategy="beforeInteractive"
      />

      {/* The unbdua script reference for ecommerce tracking */}
      <FeatureToggle featureName="ecommerceTracking">
        <Script
          async
          strategy="beforeInteractive"
          src="https://ecm.unbd.agency/api/unbdua-656172746820636c617373206d61696c.js"
        />
      </FeatureToggle>

      {/* Google Tag Manager (loads all other script tags) */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
             event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),
             dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
             f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');
              `,
        }}
      />

      {process.env.NODE_ENV !== 'development' && (
        <Script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-domain-script={oneTrustId}
        />
      )}

      <Sentry.ErrorBoundary>
        <UserAuthInfoContext.Provider
          value={{
            handleCurrentAuthenticatedUser,
            user: userInfo,
          }}
        >
          <AnnouncementContext.Provider value={initialAnnouncementContext}>
            <DropdownContext.Provider
              value={{
                handleCategoryChange,
                handleDropdownChange,
                isDropdownExpanded,
                pickedCategory,
              }}
            >
              <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                  <Component {...pageProps} />
                </ThemeProvider>
              </CacheProvider>
            </DropdownContext.Provider>
          </AnnouncementContext.Provider>
        </UserAuthInfoContext.Provider>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default App
