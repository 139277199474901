import { mergeAll } from 'ramda'

import { Account } from '@ecm/data/Account'
import { Address } from '@ecm/data/Address'
import { Billing } from '@ecm/data/Billing'
import { Cart } from '@ecm/data/Cart'
import { PlanHandle, planHandleToLabelMap, PlanLabel } from '@ecm/data/Plan'

type StripeBillingDetails = {
  addressLine?: string
  countryCode?: string
  state?: string
  city?: string
  zipCode?: string
}

export type StripeOrder = {
  billingDetails?: StripeBillingDetails
  externalSub?: string
  company?: string
  email: string
  accountFirstName: string
  accountLastName: string
  billingFirstName: string
  billingLastName: string
  planHandle: PlanHandle
  planLabel: PlanLabel
  addressIds: string[]
  couponCode?: string
}

export type StripeOrderBody = Pick<
  StripeOrder,
  | 'email'
  | 'accountFirstName'
  | 'accountLastName'
  | 'externalSub'
  | 'company'
  | 'addressIds'
  | 'billingFirstName'
  | 'billingLastName'
  | 'couponCode'
  | 'planHandle'
  | 'planLabel'
>

export type StripeOrderHash = Pick<
  StripeOrder,
  'email' | 'accountFirstName' | 'accountLastName' | 'planHandle' | 'planLabel'
>

export type SetupIntentRequest = {
  order: StripeOrderBody
  idempotencyKey: string
  sessionKey: string
  setupIntentId?: string
}

export type SetupIntentResponse = {
  client_secret: string
}

export type PaymentIntentRequest = {
  order: StripeOrderBody
  idempotencyKey: string
  sessionKey: string
}

export type PaymentIntentResponse = {
  client_secret: string
}

export type StripeOrderRequest = {
  order: StripeOrderBody
  sessionKey: string
  setupIntentId?: string
  paymentIntentId?: string
}

export const stripeOrder = (account: Account, billing: Billing, cart: Cart): StripeOrder => {

  const addressIds = cart.addresses.map((a: Address) => a.slug)
  const planHandle = cart.plan.handle as PlanHandle

  return {
    accountFirstName: account.firstName,
    accountLastName: account.lastName,
    addressIds: addressIds,
    billingDetails: {
      addressLine: billing.billingAddress,
      city: billing.city,
      countryCode: billing.countryCode,
      state: billing.stateCode,
      zipCode: billing.zipCode,
    },
    billingFirstName: billing.firstName ?? account.firstName,
    billingLastName: billing.lastName ?? account.lastName,
    company: account.company,
    couponCode: cart.coupon?.code,
    email: account.email,
    externalSub: account.externalSub,
    planHandle: planHandle,
    planLabel: planHandleToLabelMap[planHandle],
  }
}

export const stripeOrderBody = (
  account: Account,
  billing: Billing,
  cart: Cart
): StripeOrderBody => {
  const addressIds = cart.addresses.map((a: Address) => a.slug)
  const planHandle = cart.plan.handle as PlanHandle

  return {
    accountFirstName: account.firstName,
    accountLastName: account.lastName,
    addressIds: addressIds,
    billingFirstName: billing.firstName ?? account.firstName,
    billingLastName: billing.lastName ?? account.lastName,
    company: billing.company ?? account.company ?? '',
    couponCode: cart.coupon?.code,
    email: account.email,
    externalSub: account.externalSub ?? '',
    planHandle: planHandle,
    planLabel: planHandleToLabelMap[planHandle],
  }
}

export const mapStripeOrderHash = (order: StripeOrder): StripeOrderHash => ({
  accountFirstName: order.accountFirstName,
  accountLastName: order.accountLastName,
  email: order.email,
  planHandle: order.planHandle,
  planLabel: order.planLabel,
})

export const mapStripeOrderBody = (order: StripeOrder): StripeOrderBody => ({
  accountFirstName: order.accountFirstName,
  accountLastName: order.accountLastName,
  addressIds: order.addressIds,
  billingFirstName: order.billingFirstName,
  billingLastName: order.billingLastName,
  company: order.company,
  couponCode: order.couponCode,
  email: order.email,
  planHandle: order.planHandle,
  planLabel: order.planLabel,
})
