import * as Sentry from '@sentry/react'

import ManageStorage from '@ecm/capability/ManageStorage'
import { UseHubSpot } from '@ecm/capability/UseHubSpot'
import { Company, isCompany, MarketingErrorResponse } from '@ecm/data/Api'
import { Demo, toHubSpotDemo } from '@ecm/data/Demo'
import { generateHubSpotUrl, HubSpotBody, HubSpotResponse, HubSpotValues } from '@ecm/data/HubSpot'
import { Lead, leadToHubspotLead } from '@ecm/data/Lead'
import { getOrElse } from '@ecm/data/Maybe'
import { runClientEff } from '@ecm/effect'
import { post } from '@ecm/effect/Api'
import appRead from '@ecm/effect/app/Read'
import { ClientStorageKey } from '@ecm/effect/client/ManageStorage'

const createHubSpotBody = (values: HubSpotValues): HubSpotBody | void => {
  try {
    const hubSpotKey = runClientEff((eff: ManageStorage<ClientStorageKey>) => {
      return eff.getStorageItem<string>('hubspotutk')
    })
    return {
      context: {
        hutk: getOrElse(hubSpotKey, undefined),
        pageName: window.document.title,
        pageUri: window.location.href,
      },
      fields: values,
      submittedAt: Date.now(),
    }
  } catch {
    Sentry.captureMessage('there was a problem getting hubspotKey')
    console.log('there was a problem getting hubspotKey')
  }
}

export const appUseHubSpot: UseHubSpot = {
  async createDemo(d: Demo) {
    const formId = appRead.ask().hubSpot.demoFormId
    const body = createHubSpotBody(toHubSpotDemo(d))
    return post<HubSpotResponse>(generateHubSpotUrl(formId), body)
  },
  async getCompanyName(email: string): Promise<string | undefined> {
    return await runClientEff(async eff => {
      try {
        const company: Company | MarketingErrorResponse = await eff.getCompanyByEmail(email)
        if (isCompany(company)) {
          Sentry.captureMessage('Company found by e-mail')
          return company.name
        }
        Sentry.captureMessage('Company not found by e-mail')
      } catch (e) {
        Sentry.captureException(
          `An error occured while fetching company name from HS, details: ${e}`
        )
      }
    })
  },
  async submitGenericLeadForm(lead: Lead, formId: string, source?: string) {
    const hubspotBody = createHubSpotBody(leadToHubspotLead(lead, source))

    return await post<HubSpotResponse>(generateHubSpotUrl(formId), hubspotBody)
  },

  async submitLeadForm(lead: Lead) {
    const leadFormId = appRead.ask().hubSpot.formIds.mainLeadForm
    const hubspotBody = createHubSpotBody(leadToHubspotLead(lead, 'Main Sign Up Form'))

    return await post<HubSpotResponse>(generateHubSpotUrl(leadFormId), hubspotBody)
  },
}
export default appUseHubSpot
