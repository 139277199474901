import ManageCouponCode from '@ecm/capability/ManageCouponCode'
import { parseContentfulCopuponCode } from '@ecm/data/CouponCode'
import appUseContentful from '@ecm/effect/app/UseContentful'

const appManageCouponCode: ManageCouponCode = {
  getCouponCodeByNames: async (values: string[]) =>
    appUseContentful.getEntriesByFieldValues(
      'couponCode',
      'name',
      values,
      parseContentfulCopuponCode
    ),
}

export default appManageCouponCode
