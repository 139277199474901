import applyTo from 'ramda/src/applyTo'

import ManageAddress from '@ecm/capability/ManageAddress'
import ManageAnnouncement from '@ecm/capability/ManageAnnouncement'
import ManageArea from '@ecm/capability/ManageArea'
import ManageArticle from '@ecm/capability/ManageArticle'
import ManageAuthor from '@ecm/capability/ManageAuthor'
import ManageBilling from '@ecm/capability/ManageBilling'
import ManageCaseStudy from '@ecm/capability/ManageCaseStudy'
import ManageCategories from '@ecm/capability/ManageCategories'
import ManageCouponCode from '@ecm/capability/ManageCouponCode'
import ManageDemo from '@ecm/capability/ManageDemo'
import ManageFaq from '@ecm/capability/ManageFaq'
import ManageLandingPage from '@ecm/capability/ManageLandingPage'
import ManageLead from '@ecm/capability/ManageLead'
import ManagePartner from '@ecm/capability/ManagePartner'
import ManagePlan from '@ecm/capability/ManagePlan'
import ManageSnippet from '@ecm/capability/ManageSnippet'
import ManageTestimonial from '@ecm/capability/ManageTestimonial'
import ManageUserCart from '@ecm/capability/ManageUserCart'
import Read from '@ecm/capability/Read'
import Track from '@ecm/capability/Track'
import Write from '@ecm/capability/Write'
import appManageAddress from '@ecm/effect/app/ManageAddress'
import appManageAnnouncement from '@ecm/effect/app/ManageAnnouncement'
import appManageArea from '@ecm/effect/app/ManageArea'
import appManageArticle from '@ecm/effect/app/ManageArticle'
import appManageAuthor from '@ecm/effect/app/ManageAuthor'
import appManageBilling from '@ecm/effect/app/ManageBilling'
import appManageCaseStudy from '@ecm/effect/app/ManageCaseStudy'
import appManageCategories from '@ecm/effect/app/ManageCategories'
import appManageCouponCode from '@ecm/effect/app/ManageCouponCode'
import appManageDemo from '@ecm/effect/app/ManageDemo'
import appManageFaq from '@ecm/effect/app/ManageFaq'
import appManageLeads from '@ecm/effect/app/ManageLead'
import appManagePartner from '@ecm/effect/app/ManagePartner'
import { appManagePlan } from '@ecm/effect/app/ManagePlan'
import appManageLandingPage from '@ecm/effect/app/ManageProductLandingPage'
import appManageSnippet from '@ecm/effect/app/ManageSnippet'
import appManageTestimonial from '@ecm/effect/app/ManageTestimonial'
import appManageUserCart from '@ecm/effect/app/ManageUserCart'
import appRead, { AppEnv } from '@ecm/effect/app/Read'
import appWrite from '@ecm/effect/app/Write'
import clientManageStorage from '@ecm/effect/client/ManageStorage'
import { ManageClientStorage } from '@ecm/effect/client/ManageStorage'
import clientTrack from '@ecm/effect/client/Track'

/* eslint-disable @typescript-eslint/no-explicit-any */
type BaseEff = ManageAddress &
  ManageAnnouncement &
  ManageArea &
  ManageArticle &
  ManageAuthor &
  ManageBilling &
  ManageCategories &
  ManageCaseStudy &
  ManageCouponCode &
  ManageDemo &
  ManageFaq &
  ManageLead &
  ManagePartner &
  ManagePlan &
  ManageSnippet &
  ManageTestimonial &
  ManageLandingPage &
  ManageUserCart &
  Read<AppEnv> &
  Write<any>

export const baseEff: BaseEff = {
  ...appManageAddress,
  ...appManageAnnouncement,
  ...appManageArea,
  ...appManageArticle,
  ...appManageAuthor,
  ...appManageBilling,
  ...appManageCaseStudy,
  ...appManageCategories,
  ...appManageCouponCode,
  ...appManageDemo,
  ...appManageFaq,
  ...appManageLeads,
  ...appManagePartner,
  ...appManagePlan,
  ...appManageSnippet,
  ...appManageTestimonial,
  ...appManageLandingPage,
  ...appManageUserCart,
  ...appRead,
  ...appWrite,
}

type ServerEff = BaseEff

export const serverEff: ServerEff = baseEff

export type ClientEff = BaseEff & ManageClientStorage & Track

export const clientEff: ClientEff = {
  ...baseEff,
  ...clientManageStorage,
  ...clientTrack,
}

export const runEff = applyTo(baseEff)
export const runClientEff = applyTo(clientEff)
export const runServerEff = applyTo(serverEff)

export default baseEff
