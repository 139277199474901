import { Entry } from 'contentful'
import { groupBy, sortBy } from 'ramda'
import map from 'ramda/src/map'

import Addresses, { Address, ContentfulAddress } from '@ecm/data/Address'
import AddressObj from '@ecm/data/Address'

export type AreaSlug = string
export const areaSlug = (s: string): AreaSlug => s

type Area_ = {
  addresses: Address[]
  description?: string | null
  previewImageUrl?: string
  name: string
  slug: AreaSlug
  stateAbbreviation: string
  stateDescription?: string | null
}

export type Area = Area_ & {
  __Type: 'area'
  city: string
  cityDisplay: string
  stateAbbreviation: string
}

export const area = (a_: Area_): Area => {
  return {
    ...a_,
    __Type: 'area',
    city: a_.slug.substring(0, a_.slug.length - 3),
    // Get rid of everything after comma. New York, NY becomes New York
    cityDisplay: a_.name.split(',')[0],
    stateAbbreviation: a_.slug.substring(a_.slug.length - 2),
  }
}

export const createAreaFromAddresses = (a_: Area_): Area => {
  return {
    ...a_,
    __Type: 'area',
    addresses: a_.addresses,
    city: AddressObj.buildSlug(a_.name),
    cityDisplay: a_.name,
    stateAbbreviation: a_.stateAbbreviation.toLocaleLowerCase(),
  }
}

export const getAreasWithAddresses = (addresses: Address[]) => {
  const groupByCities = groupBy(function (address: Address) {
    return address.city
  })
  const goupedByCities = groupByCities(addresses)
  const unsortedAreas = Object.keys(goupedByCities).map(key => {
    return createAreaFromAddresses({
      addresses: goupedByCities[key],
      name: goupedByCities[key][0].city,
      slug: `${goupedByCities[key][0].stateAbbreviation}-${goupedByCities[key][0].city}`,
      stateAbbreviation: goupedByCities[key][0].stateAbbreviation,
    })
  })

  return sortBy((s: Area) => s.name, unsortedAreas)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ContentfulArea = {
  addresses: Entry<ContentfulAddress>[]
  name: string
  previewImage: Entry<any>
  slug: string
  stateAbbreviation: string
  description: string
  stateDescription: string
}

/* eslint-enable @typescript-eslint/no-explicit-any */
export const parseContentfulArea = (e: Entry<ContentfulArea>): Area => {
  const { addresses, name, previewImage, slug, stateAbbreviation, description, stateDescription } =
    e.fields
  return area({
    addresses: map(Addresses.parseContentful, addresses),
    description: description || null,
    name: name,
    previewImageUrl: previewImage ? previewImage.fields.file.url : null,
    slug: areaSlug(slug),
    stateAbbreviation: stateAbbreviation,
    stateDescription: stateDescription || null,
  })
}

export default {
  create: createAreaFromAddresses,
  getAreasWithAddresses: getAreasWithAddresses,
  parseContentful: parseContentfulArea,
}
