// These values come from design

// what is important to know is that the values are the upper limits of the screen size they represent
// so if you want something to happen on medium only you would use below("md")
export default {
  lg: 999999,
  md: 900,
  sm: 639,
  xs: 100,
}
