import { Cart } from '@ecm/data/Cart'

export type UserCart = {
  address_ids: string[]
  coupon_code: string | null
  plan_handle: string
}

export type SaveUserCartRequest = UserCart & {
  external_sub: string
}

export type SaveUserCartResponse = {
  cart_id: string
}

export const mapToUserCart = (cart: Cart): UserCart => ({
  address_ids: cart.addresses.map(a => a.slug),
  coupon_code: cart.coupon?.code ?? null,
  plan_handle: cart.plan.handle,
})

export const isUserCart = (obj: UserCart): obj is UserCart => {
  return 'address_ids' in obj && 'plan_handle' in obj
}

export const isSaveUserCartResponse = (obj: SaveUserCartResponse): obj is SaveUserCartResponse => {
  return 'cart_id' in obj
}
