import ManagePlan from '@ecm/capability/ManagePlan'
import Addons from '@ecm/data/Addon'
import Plans from '@ecm/data/Plan'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManagePlan: ManagePlan = {
  getAddon: async (slug: string) =>
    appUseContentful.getEntryBySlug('addon', slug, Addons.parseContentfulAddon),

  getPlan: async (slug: string) =>
    appUseContentful.getEntryBySlug('plan', slug, Plans.parseContentfulPlan),

  getPlans: async (isPreview?: boolean) =>
    appUseContentful.getEntries('plan', Plans.parseContentfulPlan, undefined, isPreview),
}

export default appManagePlan
