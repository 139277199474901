import { useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useRouter } from 'next/router'

import { Account, mapAccountFromCognito } from '@ecm/data/Account'
import { Cart } from '@ecm/data/Cart'
import { Lead, mapToLeadAddressDetails } from '@ecm/data/Lead'
import { getOrElse } from '@ecm/data/Maybe'
import { mapUserAuthInfo, UserAuth } from '@ecm/data/UserAuth'
import { runClientEff } from '@ecm/effect'
import { verifyUserAlreadyExistsInIris } from '@ecm/effect/client/UserAuth'
import { clearAuthCookies, handleCrossAppAuthCookies } from '@ecm/utils/auth'

const useUserAuthInfo = () => {
  const router = useRouter()

  const [userInfo, setUserInfo] = useState<UserAuth | undefined>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAuthenticated = (userData: any, syncCart?: boolean) => {
    setUserInfo(mapUserAuthInfo(userData))
    const newAccount = mapAccountFromCognito(userData)
    runClientEff(async eff => {
      eff.setStorageItem<Account>('account', newAccount)
      if (syncCart && newAccount.externalSub) {
        await eff.syncUserCart(newAccount.externalSub)
      }
    })
    if (router.pathname.includes('/checkout/account') && !router.asPath.includes('error_message')) {
      router.push('/checkout/billing')
    }
  }

  const submitLeadForm = async () => {
    return runClientEff(async eff => {
      Auth.currentAuthenticatedUser().then(async userData => {
        const lead: Lead = {
          email: userData.attributes.email ?? '',
          firstName: userData.attributes.given_name ?? '',
          formDetails: getOrElse(eff.getStorageItem('formDetails'), undefined),
          getStarted: true,
          lastName: userData.attributes.family_name ?? '',
          product: getOrElse(eff.getStorageItem('planName'), undefined),
          productInternalValue: getOrElse(eff.getStorageItem('planInternalValue'), undefined),
        }

        const cart = getOrElse(eff.getStorageItem<Cart>('cart'), undefined)
        if (cart && cart.addresses) {
          const address_props = mapToLeadAddressDetails(cart.addresses)
          Object.assign(lead, { addressDetails: address_props })
        }
        await eff.submitMainSignUpLead(lead)

        eff.removeStorageItem('planName')
        eff.removeStorageItem('planInternalValue')
      })
    })
  }

  const onSingOut = () => {
    setUserInfo(undefined)
    runClientEff(eff => eff.removeStorageItem('account'))
    clearAuthCookies()
  }

  const handleCurrentAuthenticatedUser = (verifyIrisAccount?: boolean, syncCart?: boolean) => {
    handleCrossAppAuthCookies()
    Auth.currentAuthenticatedUser()
      .then(async userData => {
        if (verifyIrisAccount) {
          verifyUserAlreadyExistsInIris(router, userData.signInUserSession.idToken.payload.sub)
        }
        handleAuthenticated(userData, syncCart)
      })
      .catch(() => {
        clearAuthCookies()
        if (router.pathname.includes('/checkout/billing')) {
          router.push('/checkout/account')
        }
        console.log('User is not authenticated')
      })
  }

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'signUp':
          setUserInfo(mapUserAuthInfo(data))
          break
        case 'cognitoHostedUI':
          submitLeadForm()
          break
        case 'oAuthSignOut':
          onSingOut()
          break
        case 'signOut':
          onSingOut()
          router.push('/checkout/account')
          break
      }
    })

    return unsubscribe

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  return { handleCurrentAuthenticatedUser, userInfo }
}

export default useUserAuthInfo
