import ManageStorage from '@ecm/capability/ManageStorage'
import Cookie from '@ecm/data/Cookie'
import { getOrElse, just, nothing } from '@ecm/data/Maybe'

export type ClientStorageKey =
  | 'account'
  | 'billing'
  | 'orderEstimate'
  | 'priceEstimate'
  | 'cart'
  | 'hubspotutk'
  | 'hideCheckDepositModal'
  | 'hideAnnouncement'
  | 'dLVal'
  | 'scsk'
  | 'scik'
  | 'sccs'
  | 'scoh'
  | 'orderComplete'
  | 'formDetails'
  | 'notValidCart'
  | 'planName'
  | 'planInternalValue'

type ClientStorageMethod = 'local' | 'session' | 'cookie'

type StorageMethodMap = {
  [k in ClientStorageKey]: ClientStorageMethod
}

type UseStorageScheme = {
  _scheme: StorageMethodMap
}

export type ManageClientStorage = ManageStorage<ClientStorageKey>

export const clientManageStorage: ManageClientStorage & UseStorageScheme = {
  _scheme: {
    account: 'local',
    billing: 'local',
    cart: 'session',
    dLVal: 'session', // used to store originalLocation across entire website and pass it to GTM
    formDetails: 'session',
    hideAnnouncement: 'session',
    hideCheckDepositModal: 'session',
    hubspotutk: 'cookie',
    notValidCart: 'session',
    orderComplete: 'session', // used by order-complete, in case user goes back to billing when error occurs
    orderEstimate: 'local', // used by ecommerce tracking to track order estimates
    planInternalValue: 'session',
    planName: 'session',
    priceEstimate: 'local', // used by GA to track purchase amount
    sccs: 'session', // used by Stripe Checkout to store client_secret
    scik: 'session', // used by Stripe Checkout to store idempotency_key
    scoh: 'session', // used by Stripe Checkout to store order hash
    scsk: 'session', // used by Stripe Checkout to store session_key
  },

  getStorageItem(k: ClientStorageKey) {
    const method = this._scheme[k]
    if (method === 'cookie') {
      return Cookie.get(k)
    } else {
      const storage = method === 'session' ? window.sessionStorage : window.localStorage
      const localItem = storage.getItem(k)
      return localItem ? just(JSON.parse(localItem)) : nothing
    }
  },

  removeStorageItem(k) {
    const method = this._scheme[k]
    if (method === 'cookie') {
      Cookie.delete(k)
    } else {
      const storage = method === 'session' ? window.sessionStorage : window.localStorage
      storage.removeItem(k)
    }
  },

  setStorageItem(k, v, expiry?: number) {
    const method = this._scheme[k]
    if (method === 'cookie') {
      Cookie.set(k, JSON.stringify(v), expiry)
    } else {
      const storage = method === 'session' ? window.sessionStorage : window.localStorage
      storage.setItem(k, JSON.stringify(v))
    }
  },

  updateStorageItem<T>(k: ClientStorageKey, t: Partial<T>) {
    const currentValue = getOrElse(this.getStorageItem<T>(k), {})
    this.setStorageItem(k, { ...currentValue, ...t })
  },
}

export default clientManageStorage
