export type Color = {
  darker: string
  darkest: string
  lighter: string
  lightest: string
  normal: string
}

export const lucky: Color = {
  darker: '#076938',
  darkest: '#044625',
  lighter: '#6DCF9E',
  lightest: '#9DDFBE',
  normal: '#0BAF5D',
}

export const love: Color = {
  darker: '#B32E3A',
  darkest: '#86232C',
  lighter: '#E6616D',
  lightest: '#EC8992',
  normal: '#E03A49',
}

export const mono: Color = {
  darker: 'black',
  darkest: 'black',
  lighter: '#979797',
  lightest: '#979797',
  normal: '#2C3040',
}

export const saint: Color = {
  darker: '#12215C',
  darkest: '#0E1945',
  lighter: '#45548F',
  lightest: '#747FAB',
  normal: '#172973',
}

export const slate: Color = {
  darker: '#202431',
  darkest: '#131621',
  lighter: '#393D51',
  lightest: '#4B4F63',
  normal: '#2C3040',
}

export const smoke: Color = {
  darker: '#DAE5EB',
  darkest: '#CFD9DE',
  lighter: '#EBF4F9',
  lightest: '#F0F7FA',
  normal: '#E6F1F7',
}

export const white: Color = {
  darker: 'white',
  darkest: 'white',
  lighter: 'white',
  lightest: 'white',
  normal: 'white',
}

export default {
  love,
  lucky,
  mono,
  saint,
  slate,
  smoke,
  white,
}
