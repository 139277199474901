import { EntryCollection } from 'contentful'

export type EntryMetadata_ = {
  limit: number
  skip: number
  total: number
}

export type EntryMetadata = EntryMetadata_ & { __Type: 'entryMetadata' }

export const entryMetadata = (em_: EntryMetadata_): EntryMetadata => ({
  ...em_,
  __Type: 'entryMetadata',
})

export const parseContentfulEntryMetadata = (ec: EntryCollection<EntryMetadata>): EntryMetadata => {
  return entryMetadata({
    limit: ec.limit,
    skip: ec.skip,
    total: ec.total,
  })
}
