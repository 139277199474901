import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types'
import { Typography } from '@mui/material'
import { Entry, EntryCollection } from 'contentful'

import {
  ArticleCategory,
  ContentfulArticleCategory,
  parseContentfulArticleCategory,
} from '@ecm/data/ArticleCategory'
import { ArticleFaq, ContentfulArticleFaq, parseContentfulArticleFaq } from '@ecm/data/ArticleFaq'
import { ContentfulHowTo, HowTo, parseContentfulHowTo } from '@ecm/data/ArticleHowTo'
import { ArticleTag, ContentfulArticleTag, parseContentfulArticleTag } from '@ecm/data/ArticleTag'
import { Author, ContentfulAuthor, parseContentfulAuthor } from '@ecm/data/Author'
import { ContentType } from '@ecm/data/Content'
import ScreenSize from '@ecm/data/ScreenSize'
import { runClientEff } from '@ecm/effect'
import Anchor from '@ecm/ui/element/Anchor'
import Color from '@ecm/ui/element/Color'
import ContentfulImage, { ImageType } from '@ecm/ui/element/ContentfulImage'

export type ArticleId = string

type Article_ = {
  author: Author
  categories: ArticleCategory[]
  content: Document
  contentPreview: string
  faq: ArticleFaq[]
  id: ArticleId
  howTo: HowTo | null
  mainImageUrl?: string
  publishedDate: string
  redirectTo: string | null
  seoDescription: string
  seoTitle: string
  shouldLink: boolean
  slug: string
  tags: ArticleTag[]
  title: string
}
export type Article = Article_ & { __Type: 'article' }

export const article = (a_: Article_): Article => ({
  ...a_,
  __Type: 'article',
})

export type PaginatedArticle = { items: Article[]; total: number }

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ContentfulArticle = {
  author: Entry<ContentfulAuthor>
  categories: Entry<ContentfulArticleCategory>[]
  content: Document
  contentPreview: string
  faq: Entry<ContentfulArticleFaq>[]
  howTo?: Entry<ContentfulHowTo>
  mainImage: Entry<any>
  publishedDate: string
  redirectTo: string
  seoDescription: string
  seoTitle: string
  shouldLink: boolean
  slug: string
  tags: Entry<ContentfulArticleTag>[]
  title: string
}

export const convertContentToComponents = (content: Document) => {
  return documentToReactComponents(content, {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, next) => {
        return (
          <Typography variant="p2" color={Color.slate.normal} my="0.5em">
            {next}
          </Typography>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, next) => {
        const { uri } = node.data
        return (
          <Anchor
            href={uri}
            external={
              !(
                uri.startsWith('https://www.earthclassmail.com') ||
                uri.startsWith('https://help.earthclassmail.com') ||
                uri.startsWith('https://app.earthclassmail.com')
              )
            }
            variant="p2"
          >
            {next}
          </Anchor>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { width, height } = node.data.target.fields.file.details.image

        return (
          <div style={{ maxWidth: `${width < ScreenSize.md ? width : ScreenSize.lg}px` }}>
            <ContentfulImage
              imageType={ImageType.BlogResponsive}
              width={width}
              height={height}
              alt={node.data.target.fields.title}
              src={node.data.target.fields.file.url}
              layout="responsive"
              objectFit="cover"
            />
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const { id }: { id: ContentType } = node.data.target.sys.contentType.sys

        if (id === 'imageWithLink') {
          const { title, image, url, eventName, trackingId } = node.data.target.fields
          const { width, height } = image.fields.file.details.image

          const onClick = () => {
            if (eventName) {
              runClientEff(eff => eff.trackGa({ event: eventName }))
            }
          }

          return (
            <Anchor
              href={url}
              display="block"
              maxWidth={`${width < ScreenSize.md ? width : ScreenSize.lg}px`}
              onClick={onClick}
              id={trackingId ?? ''}
            >
              <ContentfulImage
                imageType={ImageType.BlogResponsive}
                width={width}
                height={height}
                alt={title}
                src={image.fields.file.url}
                layout="responsive"
                objectFit="cover"
                unoptimized
              />
            </Anchor>
          )
        }
      },
    },
  })
}

export const parseContentfulPaginatedCollection = (
  ec: EntryCollection<ContentfulArticle>
): PaginatedArticle => ({ items: ec.items.map(parseContentfulArticle), total: ec.total })

export const parseContentfulArticle = (e: Entry<ContentfulArticle>): Article => {
  const {
    author,
    categories,
    content,
    contentPreview,
    faq,
    howTo,
    mainImage,
    publishedDate,
    redirectTo,
    seoDescription,
    seoTitle,
    shouldLink,
    slug,
    tags,
    title,
  } = e.fields
  const { id } = e.sys

  return article({
    author: parseContentfulAuthor(author),
    categories: (categories ?? []).map(parseContentfulArticleCategory),
    content: content,
    contentPreview: contentPreview,
    faq: (faq ?? []).map(parseContentfulArticleFaq),
    howTo: howTo ? parseContentfulHowTo(howTo) : null,
    id: id,
    mainImageUrl: mainImage ? mainImage.fields.file.url : null,
    publishedDate: publishedDate,
    redirectTo: redirectTo ?? null,
    seoDescription: seoDescription,
    seoTitle: seoTitle,
    shouldLink: shouldLink ?? false,
    slug: slug,
    tags: (tags ?? []).map(parseContentfulArticleTag),
    title: title,
  })
}

export default { parseContentful: parseContentfulArticle }
