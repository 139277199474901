import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful'

import {
  ContentfulTestimonial,
  parseContentfulTestimonial,
  Testimonial,
} from '@ecm/data/Testimonial'
import { Benefit } from '@ecm/ui/component/grid/Benefits'

import { parseNonBreakingSpaces } from './String'

export type LandingPageSlug = string

export const landingPageSlug = (slug: string): LandingPageSlug => slug

type landingPage_ = {
  benefitsBlock: BenefitsBlock | null
  customerListHeader?: string
  title: string
  type?: string | null
  enablePageIndexing: boolean
  finalCtaButtonText: string
  finalCtaHeading: string
  heroBlock: HeroBlock
  hubspotFormId: string
  howItWorksBlock: HowItWorksBlock
  illustration: string | null
  illustrationHeader?: string
  illustrationBody: Document
  labeledIcon: string | null
  labeledIconText: string | null
  pageName: string
  promoCode?: string
  testimonials: Testimonial
  secondaryBlockText: string | null
  seoTitle: string
  seoDescription: string
  slug: string
}

export const landingPage = (p_: landingPage_): LandingPage => ({
  ...p_,
  __Type: 'landingPage',
})

type ContentfulLandingPage = {
  benefitsBlock?: Entry<ContentfulBenefitsBlock>
  customerListHeader?: string
  enablePageIndexing: boolean
  finalCtaButtonText: string
  finalCtaHeading: string
  heroBlock: Entry<ContentfulHeroBlock>
  howItWorksBlock: Entry<ContentfulHowItWorksBlock>
  hubspotFormId: string
  illustration?: string
  illustrationHeader?: string
  illustrationBody: Document
  labeledIcon: string | null
  labeledIconText: string | null
  pageName: string
  promoCode?: string
  testimonials: Entry<ContentfulTestimonial>
  title: string
  type: string
  secondaryBlockText: string | null
  seoTitle: string
  seoDescription: string
  slug: string
}

export type ContentfulHeroBlock = {
  cta?: string
  ctaRoute?: string
  description?: Document
  heading: string
  leadFormHeading?: string
  useLeadForm: boolean
}

type HeroBlock_ = {
  cta?: string
  ctaRoute?: string
  description?: Document
  heading: string
  leadFormHeading?: string
  useLeadForm: boolean
}

export type HeroBlock = HeroBlock_ & { __Type: 'heroBlock' }

export const heroBlock = (t_: HeroBlock_): HeroBlock => ({
  ...t_,
  __Type: 'heroBlock',
})

export const empty = (): HeroBlock => {
  return {
    __Type: 'heroBlock',
    cta: '',
    ctaRoute: '',
    description: undefined,
    heading: '',
    leadFormHeading: '',
    useLeadForm: false,
  }
}

export const parseContentfulHeroBlock = (ct: Entry<ContentfulHeroBlock>): HeroBlock => {
  if (!ct) {
    return empty()
  }
  const { cta, ctaRoute, description, heading, leadFormHeading, useLeadForm } = ct.fields
  return heroBlock({
    cta: cta || '',
    ctaRoute: ctaRoute || '',
    description,
    heading: parseNonBreakingSpaces(heading),
    leadFormHeading: leadFormHeading || '',
    useLeadForm,
  })
}

export type ContentfulHowItWorksBlock = {
  body1: string
  body2: string
  body3: string
  header: string
  icon1: string
  icon2: string
  icon3: string
  subHeading1: string
  subHeading2: string
  subHeading3: string
}

type HowItWorksBlock_ = {
  body1: string
  body2: string
  body3: string
  header: string
  icon1: string
  icon2: string
  icon3: string
  subHeading1: string
  subHeading2: string
  subHeading3: string
}

export type HowItWorksBlock = HowItWorksBlock_ & { __Type: 'howItWorksBlock' }

export const howItWorksBlock = (t_: HowItWorksBlock_): HowItWorksBlock => ({
  ...t_,
  __Type: 'howItWorksBlock',
})

export const parseContentfulHowItWorksBlock = (
  ct: Entry<ContentfulHowItWorksBlock>
): HowItWorksBlock => {
  const {
    body1,
    body2,
    body3,
    header,
    icon1,
    icon2,
    icon3,
    subHeading1,
    subHeading2,
    subHeading3,
  } = ct.fields
  return howItWorksBlock({
    body1,
    body2,
    body3,
    header,
    icon1,
    icon2,
    icon3,
    subHeading1,
    subHeading2,
    subHeading3,
  })
}

export type ContentfulBenefit = {
  description: string
  iconName: string
  title: string
}

type Benefit_ = {
  description: string
  iconName: string
  title: string
}

export type Benefit = Benefit_ & { __Type: 'benefit' }

export const benefit = (t_: Benefit_): Benefit => ({
  ...t_,
  __Type: 'benefit',
})

export const parseContentfulBenefit = (ct: Entry<ContentfulBenefit>): Benefit => {
  const { description, iconName, title } = ct.fields

  return benefit({
    description,
    iconName,
    title: parseNonBreakingSpaces(title), //TODO: Move this all the way up to not just this field??????
  })
}

export type ContentfulBenefitsBlock = {
  benefits: Entry<ContentfulBenefit>[]
  header: string
  title?: string
}

type BenefitsBlock_ = {
  benefits: Benefit[]
  header: string
  title?: string
}

export type BenefitsBlock = BenefitsBlock_ & { __Type: 'benefitsBlock' }

export const benefitsBlock = (t_: BenefitsBlock_): BenefitsBlock => ({
  ...t_,
  __Type: 'benefitsBlock',
})

export const parseContentfulBenefitsBlock = (
  ct?: Entry<ContentfulBenefitsBlock> | undefined
): BenefitsBlock | undefined => {
  if (ct === undefined) {
    return ct
  } else {
    const { benefits, header, title } = ct.fields

    return benefitsBlock({
      benefits: benefits.map(parseContentfulBenefit),
      header: header || '',
      title: title || '',
    })
  }
}

export const parseContentfulLandingPage = (e: Entry<ContentfulLandingPage>): LandingPage => {
  const fields = e.fields
  return landingPage({
    benefitsBlock: parseContentfulBenefitsBlock(fields.benefitsBlock) || null,
    customerListHeader: fields.customerListHeader || '',
    enablePageIndexing: fields.enablePageIndexing,
    finalCtaButtonText: fields.finalCtaButtonText,
    finalCtaHeading: fields.finalCtaHeading,
    heroBlock: parseContentfulHeroBlock(fields.heroBlock),
    howItWorksBlock:
      fields.howItWorksBlock && parseContentfulHowItWorksBlock(fields.howItWorksBlock),
    hubspotFormId: fields.hubspotFormId || '',
    illustration: fields.illustration || null,
    illustrationBody: fields.illustrationBody || null,
    illustrationHeader: fields.illustrationHeader || '',
    labeledIcon: fields.labeledIcon || null,
    labeledIconText: fields.labeledIconText || null,
    pageName: fields.pageName,
    promoCode: fields.promoCode || '',
    secondaryBlockText: fields.secondaryBlockText || null,
    seoDescription: fields.seoDescription || '',
    seoTitle: fields.seoTitle,
    slug: fields.slug,
    testimonials: fields.testimonials && parseContentfulTestimonial(fields.testimonials),
    title: fields.title,
    type: fields.type || null,
  })
}

export type LandingPage = landingPage_ & { __Type: 'landingPage' }
