import * as Sentry from '@sentry/react'

import Track from '@ecm/capability/Track'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[]
  }
}

export const appTrack: Track = {
  trackEcommerce(obj) {
    try {
      if (typeof unbdUA !== 'undefined') {
        if (process.env.NODE_ENV === 'production') {
          unbdUA(obj)
        } else {
          console.log(obj)
        }
      }
    } catch (ex) {
      Sentry.captureException(ex)
    }
  },

  trackGa(event) {
    try {
      window.dataLayer.push(event)
    } catch (ex) {
      Sentry.captureException(ex)
    }
  },
}

export default appTrack
