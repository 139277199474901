import { Entry } from 'contentful'

/* This json blob is created during export, it pulls the data from contentful allowing static announcements */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import announcementInfo from '/public/announcement-info.json'
export type AnnouncementSlug = string

export const announcementSlug = (slug: string): AnnouncementSlug => slug

type AnnouncementData_ = {
  callToActionText?: string
  callToActionRoute?: string
  content: string
  isActive: boolean
}

export type AnnouncementData = AnnouncementData_ & { __Type: 'AnnouncementData' }

export const announcementData = (p_: AnnouncementData_): AnnouncementData => ({
  ...p_,
  __Type: 'AnnouncementData',
})

export type AnnouncementContextType = {
  announcement: AnnouncementData
  setAnnouncementOff: (announcement: AnnouncementData) => void
}

export const initialAnnouncementContext: AnnouncementContextType = {
  announcement: announcementData(announcementInfo),
  setAnnouncementOff: announcement => {
    announcement.isActive = false
  },
}

type ContentfulAnnouncement = {
  callToActionText?: string
  callToActionRoute?: string
  content: string
  isActive: boolean
}

export const parseContentfulAnnouncement = (e: Entry<ContentfulAnnouncement>): AnnouncementData => {
  const fields = e.fields

  return announcementData({
    callToActionRoute: fields.callToActionRoute,
    callToActionText: fields.callToActionText,
    content: fields.content,
    isActive: fields.isActive,
  })
}

export default { parseContentful: parseContentfulAnnouncement }
