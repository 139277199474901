import Write from '@ecm/capability/Write'
import appRead from '@ecm/effect/app/Read'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const appWrite: Write<any> = {
  tell: s => {
    const { where } = appRead.ask()
    const prodLogger = console.log
    return where === 'production' ? prodLogger(s) : console.log(s)
  },
}

export default appWrite
