import { Entry } from 'contentful'

type ArticleTag_ = {
  id: string
  name: string
  slug: string
}

export type ArticleTag = ArticleTag_ & { __Type: 'articleTag' }

export const articleTag = (at_: ArticleTag_): ArticleTag => ({
  ...at_,
  __Type: 'articleTag',
})

export type ContentfulArticleTag = {
  name: string
  slug: string
}

export const parseContentfulArticleTag = (e: Entry<ContentfulArticleTag>): ArticleTag => {
  const { name, slug } = e.fields
  const { id } = e.sys
  return articleTag({ id, name, slug })
}
