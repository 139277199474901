import appRead from '@ecm/effect/app/Read'

type HubSpotFormIds = {
  emailOnlyForm: string
  builtForm: string
  businessLeadForm: string
  contactForm: string
  individualLeadForm: string
  mainLeadForm: string
  newsletterSignup: string
  nprFormId: string
}

export type HubSpotEnv = {
  hubSpot: {
    apiUrl: string
    demoFormId: string
    portalId: string
    formIds: HubSpotFormIds
  }
}

export type HubSpotFieldKey =
  | 'customeraddresscity'
  | 'customeraddressline1'
  | 'customeraddressstate'
  | 'customeraddresszipcode'
  | 'email'
  | 'firstname'
  | 'form_details'
  | 'lastname'
  | 'newsletter_subscriber'
  | 'phone'
  | 'product_name'
  | 'source'
  | 'lifecyclestage'
  | 'product_family'
  | 'ecm_address'
  | 'ecm_address_city'
  | 'ecm_address_state'
  | 'get_started'

export type HubspotField = {
  name: HubSpotFieldKey
  value: string
}

export type HubSpotValues = HubspotField[]

export type HubSpotBody = {
  fields: HubSpotValues

  context: {
    pageUri: string
    pageName: string
    hutk?: string
  }
  submittedAt: number
}

// The HubSpot form submission error object looks like this:
// {
//   "status": "error",
//   "message": "The request is not valid",
//   "correlationId": "79832aaf-ddc7-42c8-bf21-de72884ef780",
//   "errors": [
//     {
//       "message": "Error in 'fields.email'. Invalid email address",
//       "errorType": "INVALID_EMAIL"
//     }
//   ]
// }
export type HubSpotError = {
  message: string
  errorType: string
}
export type HubSpotResponse = {
  redirectUri?: string
  inlineMessage?: string
  errors?: HubSpotError[]
}

export const formatErrors = (hubSpotErrors: HubSpotError[]) => {
  return hubSpotErrors.map(e => e.message)
}

// In many cases, it is useful to ignore an INVALID_EMAIL error. This just means
// that the supplied email address failed HubSpot's validation, and can happen
// for generic test emails like "test@test.com". Usually, there is no need to
// treat this as an error worth displaying to the user.
export const isOnlyInvalidEmailError = (hubSpotErrors: HubSpotError[]): boolean => {
  return hubSpotErrors.every(e => e.errorType === 'INVALID_EMAIL')
}

export const generateHubSpotUrl = (formId: string) =>
  `${appRead.ask().hubSpot.apiUrl}submit/${appRead.ask().hubSpot.portalId}/${formId}`
