export type FaqCategory =
  | 'check-deposit'
  | 'general'
  | 'getting-started'
  | 'storage/forwarding'
  | 'virtual-mail'
  | 'billing'
  | 'security'

export const parseContentfulFaqCategory = (s: string): FaqCategory => {
  switch (s) {
    case 'Check Deposit':
      return 'check-deposit'
    case 'General':
      return 'general'
    case 'Getting Started':
      return 'getting-started'
    case 'Storage/Forwarding':
      return 'storage/forwarding'
    case 'Virtual Mail':
      return 'virtual-mail'
    case 'Billing':
      return 'billing'
    case 'Security':
      return 'security'
    default:
      return 'general'
  }
}

export const toContentfulFaqCategory = (cat: FaqCategory): string => {
  switch (cat) {
    case 'check-deposit':
      return 'Check Deposit'
    case 'general':
      return 'General'
    case 'getting-started':
      return 'Getting Started'
    case 'storage/forwarding':
      return 'Storage/Forwarding'
    case 'virtual-mail':
      return 'Virtual Mail'
    case 'billing':
      return 'Billing'
    case 'security':
      return 'Security'
  }
}

export default {
  parseContentful: parseContentfulFaqCategory,
  toContentful: toContentfulFaqCategory,
}
