import { NextRouter } from 'next/router'

import Cookie from '@ecm/data/Cookie'
import { getOrElse } from '@ecm/data/Maybe'
import { runClientEff } from '@ecm/effect'

type CognitoCookie = {
  nameSlices: string[]
  value: string
}

const defaultExpirationMilliseconds = 31536000000 // 365 days
const cognitoCookiePrefix = 'CognitoIdentityServiceProvider'
const cookieNamesToReplace = [
  'accessToken',
  'idToken',
  'refreshToken',
  'clockDrift',
  'LastAuthUser',
  'userData',
]
const cookieNamesToDelete = ['deviceGroupKey', 'deviceKey', 'randomPasswordKey']

const buildCognitoCookieName = (parts: string[], clientId: string) =>
  parts.length > 3
    ? `${parts[0]}.${clientId}.${parts[2]}.${parts[3]}`
    : `${parts[0]}.${clientId}.${parts[2]}`

const extractCognitoCookie = (cookie: string): CognitoCookie => {
  const ccParts = cookie.split('=')
  const ccNameParts = ccParts[0].split('.')
  return {
    nameSlices: ccNameParts,
    value: ccParts[1],
  }
}

const deleteCognitoCookies = (clientId: string, cookieDomain?: string) => {
  const cognitoCookies = getCognitoCookies(clientId)
  if (cognitoCookies && cognitoCookies.length > 0) {
    cognitoCookies.forEach(cc => {
      const cognitoCookie = extractCognitoCookie(cc)

      if (!cookieNamesToDelete.includes(cognitoCookie.nameSlices[3])) {
        return
      }

      const cookieName = buildCognitoCookieName(cognitoCookie.nameSlices, clientId)
      Cookie.delete(cookieName, cookieDomain)
    })
  }
}

const getCognitoCookies = (clientId: string) =>
  getOrElse(Cookie.find(`${cognitoCookiePrefix}.${clientId}`), null)

export const handleCrossAppAuthCookies = () => {
  runClientEff(eff => {
    const amplifyConfig = eff.ask().amplify
    if (amplifyConfig.cognitoUserPoolWebClientId) {
      const cognitoCookies = getCognitoCookies(amplifyConfig.cognitoUserPoolWebClientId)
      if (cognitoCookies && cognitoCookies.length > 0) {
        cognitoCookies.forEach(cc => {
          const cognitoCookie = extractCognitoCookie(cc)

          if (
            !cookieNamesToReplace.includes(cognitoCookie.nameSlices[2]) &&
            !cookieNamesToReplace.includes(cognitoCookie.nameSlices[3])
          ) {
            return
          }

          const cookieName = buildCognitoCookieName(
            cognitoCookie.nameSlices,
            amplifyConfig.cognitoUserPoolWebClientId
          )
          const newCookieName = buildCognitoCookieName(
            cognitoCookie.nameSlices,
            amplifyConfig.cognitoUserPoolMarketingClientId
          )

          if (cognitoCookie.value) {
            Cookie.set(
              newCookieName,
              cognitoCookie.value,
              defaultExpirationMilliseconds,
              amplifyConfig.appCookieDomain,
              process.env.NODE_ENV !== 'development'
            )
            Cookie.delete(cookieName, amplifyConfig.appCookieDomain)
          }
        })
      }
    }
  })
}

export const clearAuthCookies = () => {
  runClientEff(eff => {
    const amplifyConfig = eff.ask().amplify
    deleteCognitoCookies(
      amplifyConfig.cognitoUserPoolMarketingClientId,
      amplifyConfig.appCookieDomain
    )
    deleteCognitoCookies(amplifyConfig.cognitoUserPoolWebClientId, amplifyConfig.appCookieDomain)
  })
}

export const handleCognitoPreSignupErrors = (router: NextRouter) => {
  const prefix = 'PreSignUp+failed+with+error+'
  if (!router.pathname.includes('/checkout/billing') && !window.location.href.includes(prefix)) {
    return
  }
  const error_description = new URL(window.location.href).searchParams
    .get('error_description')
    ?.replace('PreSignUp failed with error ', '')
  if (error_description) {
    router.push({
      pathname: '/checkout/account',
      query: {
        error_message: error_description,
      },
    })
  }
}
