import ManageArea from '@ecm/capability/ManageArea'
import Area, { AreaSlug } from '@ecm/data/Area'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageArea: ManageArea = {
  getArea: async (slug: AreaSlug) =>
    appUseContentful.getEntryBySlug('area', slug, Area.parseContentful),

  getAreas: async () => appUseContentful.getEntries('area', Area.parseContentful),

  getAreasByName: async (name: string) =>
    appUseContentful.getEntriesByFieldValues('area', 'name', [name], Area.parseContentful),

  getAreasByStateAbbreviation: async (state: string) =>
    appUseContentful.getEntriesByFieldValues(
      'area',
      'stateAbbreviation',
      [state],
      Area.parseContentful
    ),

  getFeaturedAreas: async () =>
    appUseContentful.getEntriesWithField('area', 'isFeatured', Area.parseContentful),
}

export default appManageArea
