import ManageLandingPage from '@ecm/capability/ManageLandingPage'
import { LandingPageSlug, parseContentfulLandingPage } from '@ecm/data/LandingPage'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageLandingPage: ManageLandingPage = {
  getLandingPage: async (slug: LandingPageSlug, isPreview?: boolean) => {
    return appUseContentful.getEntryBySlug(
      'landingPage',
      slug,
      parseContentfulLandingPage,
      isPreview
    )
  },

  getLandingPages: async (isPreview: boolean) =>
    appUseContentful.getEntries('landingPage', parseContentfulLandingPage, undefined, isPreview),
}

export default appManageLandingPage
