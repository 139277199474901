import { HubSpotValues } from '@ecm/data/HubSpot'
import { partialValidator } from '@ecm/data/Validation'

export type Demo = {
  email: string
  firstName: string
  lastName: string
  phone: string
}

export const emptyDemo = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
}

export type RequestDemoResponse = {
  message: string | undefined
  status: 'error' | 'success'
}

export const validateDemo = (pd: Partial<Demo>): Demo =>
  partialValidator(pd, emptyDemo, 'DemoRequestError')

export const toHubSpotDemo = (d: Demo): HubSpotValues => [
  { name: 'email', value: d.email || '' },
  { name: 'firstname', value: d.firstName || '' },
  { name: 'lastname', value: d.lastName || '' },
  { name: 'phone', value: d.phone || '' },
  { name: 'source', value: 'Demo Form' },
]

export default {
  toHubSpot: toHubSpotDemo,
  validate: validateDemo,
}
