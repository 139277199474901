import { Entry } from 'contentful'

type ArticleCategory_ = {
  id: string
  name: string
  slug: string
}

export type ArticleCategory = ArticleCategory_ & { __Type: 'articleCategory' }

export const articleCategory = (ac_: ArticleCategory_): ArticleCategory => ({
  ...ac_,
  __Type: 'articleCategory',
})

export type ContentfulArticleCategory = {
  name: string
  slug: string
}

export const parseContentfulArticleCategory = (
  e: Entry<ContentfulArticleCategory>
): ArticleCategory => {
  const { name, slug } = e.fields
  const { id } = e.sys
  return articleCategory({ id, name, slug })
}
