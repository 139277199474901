import ManageBilling from '@ecm/capability/ManageBilling'
import { Account } from '@ecm/data/Account'
import { isStatusCheck } from '@ecm/data/Api'
import { Billing } from '@ecm/data/Billing'
import { Cart } from '@ecm/data/Cart'
import { CouponCode } from '@ecm/data/Coupon'
import appUseMarketingApi from '@ecm/effect/app/UseMarketingApi'

export const appManageBilling: ManageBilling = {
  checkOrderStatus: async (uuid?: string) => {
    const status = await appUseMarketingApi.checkOrderStatus(uuid)
    if (isStatusCheck(status)) {
      return status
    } else {
      throw Error('error in order status check')
    }
  },

  getCompanyByEmail: (email: string) => {
    return appUseMarketingApi.getCompanyByEmail(email)
  },

  getCoupon: async (code: CouponCode) => {
    return appUseMarketingApi.getCoupon(code)
  },

  getOrderEstimate: async (cart: Cart, billing?: Billing) => {
    return appUseMarketingApi.getOrderEstimate(cart, billing)
  },

  submitOrder: async (
    account: Account,
    billing: Billing,
    cart: Cart,
    setupIntentId?: string,
    paymentIntentId?: string
  ) => {
    const response = await appUseMarketingApi.submitOrder(
      account,
      billing,
      cart,
      setupIntentId,
      paymentIntentId
    )
    return response
  },
}
export default appManageBilling
