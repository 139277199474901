import { HubSpotValues } from '@ecm/data/HubSpot'

import { Address } from './Address'
import { LeadAddressInfo } from './LeadAddressInfo'

export type LeadAddressDetails = {
  addressCity?: string,
  addressLine1?: string,
  addressState?: string,
  addressZipcode?: string,
}

export type Lead = {
  addressDetails?: LeadAddressDetails
  email: string
  firstName?: string
  fullName?: string
  formDetails?: string
  lastName?: string
  phoneNumber?: string
  product?: string
  productInternalValue?: string
  lifeCycleStage?: string
  productFamily?: string
  addressInfo?: LeadAddressInfo
  newsletterSubscriber?: string
  getStarted?: boolean
}

export const leadToHubspotLead = (lead: Lead, source?: string): HubSpotValues => {
  const hsValues: HubSpotValues = [
    { name: 'email', value: lead.email || '' },
    { name: 'firstname', value: lead.firstName || '' },
    { name: 'form_details', value: lead.formDetails || '' },
    { name: 'lastname', value: lead.lastName || '' },
    { name: 'lifecyclestage', value: lead.lifeCycleStage || '' },
    { name: 'newsletter_subscriber', value: lead.newsletterSubscriber || '' },
    { name: 'phone', value: lead.phoneNumber || '' },
    { name: 'product_name', value: lead.productInternalValue ? lead.productInternalValue : '' },
    { name: 'source', value: source || '' },
    { name: 'product_family', value: lead.productFamily || '' },
    { name: 'ecm_address', value: lead.addressInfo?.street || '' },
    { name: 'ecm_address_city', value: lead.addressInfo?.city || '' },
    { name: 'ecm_address_state', value: lead.addressInfo?.state || '' },
    { name: 'customeraddresscity', value: lead.addressDetails?.addressCity || '' },
    { name: 'customeraddressline1', value: lead.addressDetails?.addressLine1 || '' },
    { name: 'customeraddressstate', value: lead.addressDetails?.addressState || '' },
    { name: 'customeraddresszipcode', value: lead.addressDetails?.addressZipcode || '' },
  ]

  if (lead.getStarted) {
    hsValues.push({ name: 'get_started', value: 'true' })
  }

  return hsValues
}

export const mapToLeadAddressDetails = (addresses: Address[]) : LeadAddressDetails => ({
  addressCity: addresses.map(a => a.city).join(";"),
  addressLine1: addresses.map(a => a.street1).join(";"),
  addressState: addresses.map(a => a.state).join(";"),
  addressZipcode: addresses.map(a => a.zip).join(";"),
})
