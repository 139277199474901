import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful'

import {
  ContentfulTestimonial,
  parseContentfulTestimonial,
  Testimonial,
} from '@ecm/data/Testimonial'

export type PartnerSlug = string

export const partnerSlug = (slug: string): PartnerSlug => slug

type Partner_ = {
  discountPercent?: number
  heading1: string
  heroDescription: string
  heroDescriptionRich: Document | null
  hubSpotFormId: string
  mapPinHeader?: string
  mapPinBody: Document | null
  name: string
  promoCode?: string
  slug: PartnerSlug
  testimonials: Testimonial[]
  rocketShipBody: Document | null
  rocketShipHeader?: string
  stackOfMailHeader?: string
  stackOfMailBody: Document | null
}

export type Partner = Partner_ & { __Type: 'partner' }

export const partner = (p_: Partner_): Partner => ({
  ...p_,
  __Type: 'partner',
})

type ContentfulPartner = {
  discountPercent?: number
  heading1: string
  heroDescription: string
  heroDescriptionRich: Document | null
  mapPinHeader?: string
  mapPinBody: Document | null
  name: string
  promoCode?: string
  slug: string
  hubSpotFormId: string
  testimonials?: Entry<ContentfulTestimonial>[]
  rocketShipBody: Document | null
  rocketShipHeader?: string
  stackOfMailHeader?: string
  stackOfMailBody: Document | null
}

export const parseContentfulPartner = (e: Entry<ContentfulPartner>): Partner => {
  const fields = e.fields

  return partner({
    discountPercent: fields.discountPercent,
    heading1: fields.heading1,
    heroDescription: fields.heroDescription,
    heroDescriptionRich: fields.heroDescriptionRich || null,
    hubSpotFormId: fields.hubSpotFormId,
    mapPinBody: fields.mapPinBody || null,
    mapPinHeader: fields.mapPinHeader || '',
    name: fields.name,
    promoCode: fields.promoCode,
    rocketShipBody: fields.rocketShipBody || null,
    rocketShipHeader: fields.rocketShipHeader || '',
    slug: partnerSlug(fields.slug),
    stackOfMailBody: fields.stackOfMailBody || null,
    stackOfMailHeader: fields.stackOfMailHeader || '',
    testimonials: (fields.testimonials || []).map(parseContentfulTestimonial),
  })
}

export default { parseContentful: parseContentfulPartner }
