import { Entry } from 'contentful'

import {
  ArticleStep,
  ContentfulArticleStep,
  parseContentfulArticleStep,
} from '@ecm/data/ArticleStep'

type HowTo_ = {
  stepsHeader: string
  steps: ArticleStep[]
  cost: string | null
  time: string | null
  timeIso: string | null
  supply: string | null
  tool: string | null
}

export type HowTo = HowTo_ & { __Type: 'howto' }

export const howto = (a_: HowTo_): HowTo => ({
  ...a_,
  __Type: 'howto',
})

export type ContentfulHowTo = {
  stepsHeader: string
  steps: Entry<ContentfulArticleStep>[]
  cost?: string
  time?: string
  timeIso?: string
  supply?: string
  tool?: string
}

export const parseContentfulHowTo = (e: Entry<ContentfulHowTo>): HowTo => {
  const { cost, stepsHeader, steps, supply, time, timeIso, tool } = e.fields

  return howto({
    cost: cost ?? null,
    steps: (steps ?? []).map(parseContentfulArticleStep),
    stepsHeader,
    supply: supply ?? null,
    time: time ?? null,
    timeIso: timeIso ?? null,
    tool: tool ?? null,
  })
}

export default { parseContentful: parseContentfulHowTo }
