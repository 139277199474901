import { Document } from '@contentful/rich-text-types'
import { Asset, Entry } from 'contentful'
import { StaticImageData } from 'next/image'

import { ensure } from '@ecm/utils/ensure'

type CaseStudy_ = {
  organizationName: string
  description: Document
  industry: string[]
  missionDescription: Document
  header: string
  subHeader: Document
  quote: string
  summaryDescription: Document
  challengesDescription: Document
  theProblemAtLargeDescription: Document
  solutionDescription: Document
  resultsDescription: Document
  pdfUrl: string
  logoImg: StaticImageData
  heroImg: StaticImageData
  slug: string
}

export type CaseStudy = CaseStudy_ & { __Type: 'caseStudies' }

const caseStudy = (c_: CaseStudy_): CaseStudy => ({
  ...c_,
  __Type: 'caseStudies',
})

type ContentfulCaseStudy = CaseStudy & {
  pdfLink: Asset
  logo: Asset
  headerImage: Asset
}

export const parseCaseStudy = (e: Entry<ContentfulCaseStudy>): CaseStudy => {
  const {
    challengesDescription,
    description,
    header,
    organizationName,
    industry,
    missionDescription,
    theProblemAtLargeDescription,
    quote,
    resultsDescription,
    slug,
    solutionDescription,
    subHeader,
    summaryDescription,
  } = e.fields

  const pdfUrl = e.fields.pdfLink.fields.file.url
  const logoImg = {
    height: ensure(e.fields.logo.fields.file.details.image).height,
    src: e.fields.logo.fields.file.url,
    width: ensure(e.fields.logo.fields.file.details.image).width,
  }
  const heroImg = {
    height: ensure(e.fields.headerImage.fields.file.details.image).height,
    src: e.fields.headerImage.fields.file.url,
    width: ensure(e.fields.headerImage.fields.file.details.image).width,
  }

  return caseStudy({
    challengesDescription,
    description,
    header,
    heroImg,
    industry,
    logoImg,
    missionDescription,
    organizationName,
    pdfUrl,
    quote,
    resultsDescription,
    slug,
    solutionDescription,
    subHeader,
    summaryDescription,
    theProblemAtLargeDescription,
  })
}
