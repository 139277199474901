import { sortBy } from 'ramda'

import { Area } from './Area'

export type StateAbbreviation =
  | 'AA'
  | 'AE'
  | 'AK'
  | 'AL'
  | 'AP'
  | 'AR'
  | 'AS'
  | 'AZ'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'IA'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'MA'
  | 'MD'
  | 'ME'
  | 'MI'
  | 'MN'
  | 'MO'
  | 'MS'
  | 'MT'
  | 'NC'
  | 'ND'
  | 'NE'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NV'
  | 'NY'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UM'
  | 'UT'
  | 'VA'
  | 'VI'
  | 'VT'
  | 'WA'
  | 'WI'
  | 'WV'
  | 'WY'

export const states: { [key in StateAbbreviation]: string } = {
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AK: 'Alaska',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UM: 'US Minor Outlying Islands',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
}

export type StateSlug = string
export const stateSlug = (s: string): StateSlug => s

type State_ = {
  code: string
  name: string
  slug: StateSlug
  description: string | null
}

export type State = State_ & {
  __Type: 'state'
  areas: Area[]
}

export const getStateLabel = (code: string) => {
  try {
    return lookupState(code)
  } catch {
    return null
  }
}

export const lookupState = (code: string) => {
  const state: string = states[code.toUpperCase() as StateAbbreviation]
  if (state) {
    return state
  }
  throw new Error('State abbreviation not recognized')
}

export const createState = (code: string, areas: Area[]): State => {
  const stateCode = code.toUpperCase()
  const stateName = lookupState(stateCode)
  const stateDescription = areas[0].stateDescription ? areas[0].stateDescription : null
  return {
    __Type: 'state',
    areas: areas,
    code: stateCode,
    description: stateDescription,
    name: stateName,
    slug: stateCode.toLowerCase(),
  }
}

export const parseContentfulStateAbbreviation = (s: string): StateAbbreviation =>
  s as StateAbbreviation

export default {
  create: createState,
  lookup: lookupState,
  parseContentful: parseContentfulStateAbbreviation,
}

export function filterStatesBySearchString(states: State[], searchValue: string) {
  const matchedStates = states.filter(
    f =>
      f.code.toLowerCase().startsWith(searchValue.toLowerCase()) ||
      f.name.toLowerCase().startsWith(searchValue.toLowerCase())
  )

  const remainingStates: State[] = states.filter(s => !matchedStates.includes(s))
  const matchedCities = remainingStates.reduce<State[]>((accum, cur) => {
    const matchedCites = cur.areas.filter((a: Area) =>
      a.cityDisplay.toLowerCase().startsWith(searchValue.toLowerCase())
    )
    if (matchedCites.length) {
      accum.push(createState(cur.code, matchedCites))
    }
    return accum
  }, [])

  return sortBy(s => s.name, [...matchedStates, ...matchedCities])
}
