import React from 'react'
import * as Sentry from '@sentry/react'
import { Auth } from 'aws-amplify'
import { NextRouter } from 'next/router'

import { apiClient } from '@ecm/effect/client/ClientApi'
import Anchor from '@ecm/ui/element/Anchor'

import appRead from '../app/Read'

const baseClientAppUrl = (() => appRead.ask().clientAppUrl)()

const handleSignOut = () => Auth.signOut().then(() => window.location.replace(location.pathname))

const handleUserRedirect = (router: NextRouter) => {
  router.push({
    pathname: '/checkout/account',
    query: {
      error_message: INVALID_OR_EXISTING_EMAIL_MSG,
      error_type: 'iris_err',
    },
  })
}

const handleApiErrorRedirect = (router: NextRouter) => {
  router.push({
    pathname: '/checkout/account',
    query: {
      error_message: IRIS_API_ERROR_MSG,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logApiErrorResponse = (error: any, externalSub?: string) => {
  Sentry.captureException(
    `Error response from iris: [${error.data.error.code} - ${error.data.error.type}] - ${error.data.error.message} / externalSub: ${externalSub}`
  )
}

const TERMINATED_OR_CLOSED_ACCOUNT_STATE_MSG =
  'Account is closed or terminated, please contact support.'

export const INCORRECT_USERNAME_OR_PASSWORD_MSG = 'Incorrect username or password.'

export const INVALID_OR_EXISTING_EMAIL_MSG =
  'The given e-mail address is invalid or already taken. If you have an account in our system, try to log in.'

export const IRIS_API_ERROR_MSG =
  'An error occurred while communicating with the API. Please contact our support.'

export const INVALID_OR_EXISTING_EMAIL_LOGIN_ONLY: React.ReactNode = (
  <>
    {INVALID_OR_EXISTING_EMAIL_MSG.replace('log in.', ' ')}
    <Anchor href={`${baseClientAppUrl}/login`} variant="p4" external>
      log in
    </Anchor>
    .
  </>
)

export const INVALID_OR_EXISTING_EMAIL_LOGIN_LOGOUT: React.ReactNode = (
  <>
    {INVALID_OR_EXISTING_EMAIL_LOGIN_ONLY} Otherwise if you want to create a new account then go{' '}
    <Anchor onClick={handleSignOut} variant="p4" href="">
      here
    </Anchor>{' '}
    and start checkout again.
  </>
)

export const getCognitoToken = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  return cognitoUser.signInUserSession.idToken.jwtToken
}

export const verifyUserAlreadyExistsInIris = async (router: NextRouter, externalSub?: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await apiClient.get('/user')
    // We do not allow to create multiple accounts in Iris with the same email address
    // So in case user exists in iris we make redirection to `checkout/account` page
    if (response.status === 200) {
      handleUserRedirect(router)
    } else if (response.status === 401) {
      // Allowing to create account
    } else {
      handleApiErrorRedirect(router)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error && error.status === 401) {
      // We allow to create account when response status from `/user` endpoint equals 401,
      // however it could mean that user either does not exist in iris or exists, but
      // the accociated account may be in `closed`, `terminated` status or being marked as fraud.
      // In that case we have to check the response error message to validate properly the account status.
      if (error.data && error.data.error.message === TERMINATED_OR_CLOSED_ACCOUNT_STATE_MSG) {
        logApiErrorResponse(error, externalSub)
        handleUserRedirect(router)
      } else {
        // Allowing to create account
      }
    } else {
      logApiErrorResponse(error, externalSub)
      handleApiErrorRedirect(router)
    }
  }
}
