import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useCategoryDropdown = () => {
  const [isDropdownExpanded, setDropdownExpanded] = useState(false)
  const [pickedCategory, setPickedCategory] = useState<string | undefined>()

  const router = useRouter()

  const handleDropdownChange = (value?: boolean) => {
    setDropdownExpanded(prev => (value ? value : !prev))
  }

  const handleCategoryChange = (category?: string) => {
    setPickedCategory(category)
  }

  useEffect(() => {
    if (!router.pathname.includes('/blog')) {
      handleCategoryChange(undefined)
    }
  }, [router.pathname])

  return { handleCategoryChange, handleDropdownChange, isDropdownExpanded, pickedCategory }
}

export default useCategoryDropdown
