export type HtmlString = string

export const capitalize = (s: string) => s.replace(/^\w/, c => c.toUpperCase())

export const capitalizeWords = (phrase: string) => phrase.replace(/\b\w/g, c => c.toUpperCase())

export const parseNonBreakingSpaces = (phrase: string) => phrase.replace(/&nbsp;/g, '\u00a0')

export type StringOfLength<Min, Max> = string & {
  __value__: never // this is the phantom type
}

const isStringOfLength = <Min extends number, Max extends number>(
  str: string,
  min: Min,
  max: Max
): str is StringOfLength<Min, Max> => str.length >= min && str.length <= max

export const stringOfLength = <Min extends number, Max extends number>(
  input: unknown,
  min: Min,
  max: Max
): StringOfLength<Min, Max> => {
  if (typeof input !== 'string') {
    throw new Error('invalid input')
  }

  if (!isStringOfLength(input, min, max)) {
    throw new Error('input is not between specified min and max')
  }

  return input // the type of input here is now StringOfLength<Min,Max>
}
