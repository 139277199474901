import * as Sentry from '@sentry/react'
import { assocPath } from 'ramda'

import ManageUserCart from '@ecm/capability/ManageUserCart'
import { isMarketingError } from '@ecm/data/Api'
import { Cart } from '@ecm/data/Cart'
import { isCoupon } from '@ecm/data/Coupon'
import { getOrElse } from '@ecm/data/Maybe'
import { mapToUserCart, UserCart } from '@ecm/data/UserCart'
import { runClientEff } from '@ecm/effect'
import appUseMarketingApi from '@ecm/effect/app/UseMarketingApi'

const isCartSyncedWithStorage = (cart: Cart, userCart: UserCart) =>
  JSON.stringify(mapToUserCart(cart)) === JSON.stringify(userCart)

const updateUserCart = async (cart: Cart, externalSub: string) => {
  const response = await appUseMarketingApi.saveUserCart({
    external_sub: externalSub,
    ...mapToUserCart(cart),
  })
  if (isMarketingError(response)) {
    Sentry.captureException(
      `An error occured while saving user cart, error: [ ${response.error.code} ] ${
        response.error.message
      }, details:  ${response.error.detail?.join(';')}`
    )
  }
}

export const appManageUserCart: ManageUserCart = {
  async syncUserCart(externalSub) {
    await runClientEff(async eff => {
      const cart = getOrElse(eff.getStorageItem<Cart>('cart'), undefined)
      let userCart = await appUseMarketingApi.findUserCart(externalSub)

      if (userCart && !isMarketingError(userCart)) {
        if (cart) {
          if (
            isCartSyncedWithStorage(cart, {
              address_ids: userCart.address_ids,
              coupon_code: userCart.coupon_code,
              plan_handle: userCart.plan_handle,
            })
          ) {
            return
          }
          userCart = mapToUserCart(cart)
        }

        const plan = await eff.getPlan(userCart.plan_handle)
        const addresses = await Promise.all(
          userCart.address_ids.map(async (slug: string) => eff.getAddress(slug))
        )

        const newCart: Partial<Cart> = {
          addons: [],
          addresses: addresses,
          plan: plan,
        }
        if (userCart.coupon_code) {
          const coupon = await eff.getCoupon(userCart.coupon_code)
          if (isCoupon(coupon)) {
            assocPath(['coupon'], coupon, newCart)
          }
        }
        eff.updateStorageItem<Cart>('cart', newCart)
      }

      if (cart) {
        await updateUserCart(cart, externalSub)
      }
    })
  },
}

export default appManageUserCart
