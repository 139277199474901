import ManageSnippet from '@ecm/capability/ManageSnippet'
import Snippet, { SnippetSlug } from '@ecm/data/Snippet'
import appUseContentful from '@ecm/effect/app/UseContentful'

export const appManageSnippet: ManageSnippet = {
  getSnippet: async (slug: SnippetSlug) =>
    appUseContentful.getEntryBySlug('snippet', slug, Snippet.parseContentful),
}

export default appManageSnippet
