import Read from '@ecm/capability/Read'
import { env } from '@ecm/config/Env'
import { AmplifyEnv } from '@ecm/data/Amplify'
import { ApiEnv } from '@ecm/data/Api'
import { ContentfulEnv } from '@ecm/data/Contentful'
import { ElasticApmEnv } from '@ecm/data/ElasticApm'
import { LogLevel, Where } from '@ecm/data/Env'
import { FeatureToggle } from '@ecm/data/FeatureToggle'
import { GoogleMapsEnv } from '@ecm/data/GoogleMaps'
import { HubSpotEnv } from '@ecm/data/HubSpot'

export type AppEnv = ApiEnv &
  ContentfulEnv &
  GoogleMapsEnv &
  HubSpotEnv &
  FeatureToggle &
  ElasticApmEnv &
  AmplifyEnv & {
    appUrl: string
    accountSignupExpId: string
    clientAppUrl: string
    facebookVerificationKey: string
    googleVerificationKey: string
    googleTagManagerId: string
    googleOptimizeId: string
    explainerVideoFileUrl: string
    loadCloudflareImage: boolean
    logLevel: LogLevel
    mobileAppsVideoUrl: string
    oneTrustId: string
    sentryDsn: string
    shareASaleMerchantId: string
    where: Where
    enableContentfulPreview: boolean
  }

export const appRead: Read<AppEnv> = {
  ask: () => ({
    accountSignupExpId: env.accountSignupExpId,
    amplify: {
      appCookieDomain: env.appCookieDomain,
      cognitoDomain: env.cognitoDomain,
      cognitoRedirectSignIn: env.cognitoRedirectSignIn,
      cognitoRedirectSignOut: env.cognitoRedirectSignOut,
      cognitoRegion: env.cognitoRegion,
      cognitoUserPoolId: env.cognitoUserPoolId,
      cognitoUserPoolMarketingClientId: env.cognitoUserPoolMarketingClientId,
      cognitoUserPoolWebClientId: env.cognitoUserPoolWebClientId,
    },
    api: { domain: env.ecmApiUrl },
    appUrl: env.appBaseUrl,
    clientAppUrl: env.clientAppBaseUrl,
    contentful: {
      accessToken: env.contentfulAccessToken,
      previewToken: env.contentfulPreviewToken,
      space: env.contentfulSpace,
    },
    elasticApm: {
      environment: env.elasticApmEnvironment,
      serverUrl: env.elasticApmServerUrl,
      serviceName: env.elasticApmServiceName,
      serviceVersion: env.elasticApmServiceVersion,
      tracingOrigins: env.elasticApmTracingOrigins,
    },
    enableContentfulPreview: env.enableContentfulPreview,
    explainerVideoFileUrl: env.explainerVideoFileUrl,
    facebookVerificationKey: env.facebookVerificationKey,
    featureToggle: {
      ecommerceTracking: env.enableEcommerceTracking,
    },
    googleMaps: { apiKey: env.googleMapsApiKey },
    googleOptimizeId: env.googleOptimizeId,
    googleTagManagerId: env.googleTagManagerId,
    googleVerificationKey: env.googleVerificationKey,
    hubSpot: {
      apiUrl: env.hubSpotApiUrl,
      demoFormId: env.hubSpotDemoFormId,
      formIds: {
        builtForm: env.hubSpotBuiltFormId,
        businessLeadForm: env.hubSpotBusinessFormId,
        contactForm: env.hubSpotContactFormId,
        emailOnlyForm: env.hubSpotEmailOnlyFormId,
        individualLeadForm: env.hubSpotIndividualFormId,
        mainLeadForm: env.hubSpotLeadFormId,
        newsletterSignup: env.hubSpotNewsletterSignupFormId,
        nprFormId: env.hubSpotNprFormId,
      },
      portalId: env.hubSpotPortalId,
    },
    loadCloudflareImage: env.loadCloudflareImage,
    logLevel: 'info',
    mobileAppsVideoUrl: env.mobileAppsVideoUrl,
    oneTrustId: env.oneTrustId,
    sentryDsn: env.sentryDSN,
    shareASaleMerchantId: env.shareASaleMerchantId,
    where: 'production',
  }),
}

export default appRead
