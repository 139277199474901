import * as Sentry from '@sentry/react'

import { HttpResponse } from '@ecm/data/Api'

async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request)
  try {
    response.parsedBody = await response.json()

    if (!response.ok) {
      Sentry.captureException(response.statusText)
    }
  } catch (ex) {
    // for 404 log message, without parameters
    if (response.status === 404) {
      Sentry.captureMessage(
        `API responded with 404 status code from URL: ${response.url.replace(/\?.*$/g, '')}`
      )
    } else {
      Sentry.captureException(ex)
    }
  }
  return response
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: 'get' }
): Promise<HttpResponse<T>> {
  return http<T>(new Request(path, args))
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  }
): Promise<HttpResponse<T>> {
  return http<T>(new Request(path, args))
}

export async function put<T>(
  path: string,
  body: Body,
  args: RequestInit = {
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    method: 'put',
  }
): Promise<HttpResponse<T>> {
  return http<T>(new Request(path, args))
}
