import { Asset, Entry } from 'contentful'

export type AuthorId = string

type Author_ = {
  fullname: string
  id: AuthorId
  photoUrl?: string | null
  role?: string | null
}

export type Author = Author_ & { __Type: 'author' }

export const author = (a_: Author_): Author => ({
  ...a_,
  __Type: 'author',
})

export type ContentfulAuthor = {
  fullname: string
  photo?: Asset
  role?: string
}

export const parseContentfulAuthor = (e: Entry<ContentfulAuthor>): Author => {
  const { fullname, photo, role } = e.fields
  const { id } = e.sys

  return author({
    fullname,
    id,
    photoUrl: photo ? photo.fields.file.url : null,
    role: role ? role : null,
  })
}

export default { parseContentful: parseContentfulAuthor }
