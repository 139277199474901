import * as Contentful from 'contentful'

import UseContentful from '@ecm/capability/UseContentful'
import { ContentQuery, ContentQueryOperators, ContentType } from '@ecm/data/Content'
import appRead from '@ecm/effect/app/Read'

const DEFAULT_INCLUDE = 10

/* eslint-disable @typescript-eslint/no-explicit-any */
export const appUseContentful: UseContentful = {
  _client: undefined,
  _previewClient: undefined,

  getClient() {
    if (!this._client) {
      const contentfulProps = appRead.ask().contentful
      this._client = Contentful.createClient({
        accessToken: contentfulProps.accessToken,
        space: contentfulProps.space,
      })
    }
    return this._client
  },

  getPreviewClient() {
    if (!this._previewClient) {
      const contentfulProps = appRead.ask().contentful
      this._previewClient = Contentful.createClient({
        accessToken: contentfulProps.previewToken,
        host: 'preview.contentful.com',
        space: contentfulProps.space,
      })
    }
    return this._previewClient
  },

  // eslint-disable-next-line sort-keys
  async getEntries<E>(
    contentType: ContentType,
    parser: (contentfulEntry: any) => E,
    query?: ContentQuery,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entries = await client.getEntries({
        ...query,
        content_type: contentType,
        include: DEFAULT_INCLUDE,
        limit: query?.limit || 1000,
      })
      return entries.items.map(parser)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntriesByFieldValues<E>(
    contentType: ContentType,
    field: string,
    values: string[],
    parser: (contentfulEntry: any) => E,
    operator: ContentQueryOperators = '[all]',
    query?: ContentQuery,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entries = await client.getEntries({
        ...query,
        content_type: contentType,
        [`fields.${field}${operator}`]: values.join(','),
        include: DEFAULT_INCLUDE,
      })
      return entries.items.map(parser)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntriesByLinkedEntry<E>(
    contentType: ContentType,
    linkedEntryId: string,
    parser: (contentfulEntry: any) => E,
    query?: ContentQuery,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entries = await client.getEntries({
        ...query,
        content_type: contentType,
        include: DEFAULT_INCLUDE,
        links_to_entry: linkedEntryId,
      })
      return entries.items.map(parser)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntriesByLinkedEntryWithField<E>(
    contentType: ContentType,
    field: string,
    parser: (e: any) => E,
    linkedEntryId?: string,
    query?: ContentQuery
  ) {
    try {
      const entries = await this.getClient().getEntries({
        ...query,
        content_type: contentType,
        [`fields.${field}`]: true,
        include: DEFAULT_INCLUDE,
        limit: query?.limit ?? 300,
        links_to_entry: linkedEntryId ?? /.*/,
      })
      return entries.items.map(parser)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntriesWithField<E>(contentType: ContentType, field: string, parser: (e: any) => E) {
    try {
      const entries = await this.getClient().getEntries({
        content_type: contentType,
        [`fields.${field}[exists]`]: true,
        include: DEFAULT_INCLUDE,
      })
      return entries.items.map(parser)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntry<E>(id: string, parser: (contentfulEntry: any) => E) {
    try {
      const entry = await this.getClient().getEntry(id)
      return parser(entry)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntryBySlug<E>(
    contentType: ContentType,
    slug: string,
    parser: (contentfulEntry: any) => E,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entry = (await client.getEntries({
        content_type: contentType,
        'fields.slug': slug,
        include: DEFAULT_INCLUDE,
      })) as any
      return parser(entry.items[0])
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntryBySlugAndAreaSlug<E>(
    contentType: ContentType,
    slug: string,
    areaSlug: string,
    parser: (contentfulEntry: any) => E,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entry = (await client.getEntries({
        content_type: contentType,
        'fields.areaSlug': areaSlug,
        'fields.slug': slug,
        include: DEFAULT_INCLUDE,
      })) as any
      return parser(entry.items[0])
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntryMetadata<E>(contentType: ContentType, parser: (contentfulEntry: any) => E) {
    try {
      const entriesMetadata = await this.getClient().getEntries({
        content_type: contentType,
        include: 0,
        limit: 0,
      })
      return parser(entriesMetadata)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntryMetadataByFieldValue<E>(
    contentType: ContentType,
    field: string,
    parser: (contentfulEntry: any) => E
  ) {
    try {
      const entriesMetadata = await this.getClient().getEntries({
        content_type: contentType,
        include: 0,
        limit: 0,
        [`fields.${field}`]: true,
      })
      return parser(entriesMetadata)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getEntryMetadataById<E>(
    contentType: ContentType,
    linkedEntryId: string,
    parser: (contentfulEntry: any) => E
  ) {
    try {
      const entriesMetadata = await this.getClient().getEntries({
        content_type: contentType,
        include: 0,
        limit: 0,
        links_to_entry: linkedEntryId,
      })
      return parser(entriesMetadata)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getPaginatedEntries<E>(
    contentType: ContentType,
    parser: (contentfulEntryCollection: any) => E,
    query?: ContentQuery,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entries = await client.getEntries({
        ...query,
        content_type: contentType,
        include: DEFAULT_INCLUDE,
        limit: query?.limit || 1000,
      })
      return parser(entries)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  async getPaginatedEntriesByLinkedEntry<E>(
    contentType: ContentType,
    linkedEntryId: string,
    parser: (contentfulEntryCollection: any) => E,
    query?: ContentQuery,
    isPreview?: boolean
  ) {
    try {
      const client = isPreview ? this.getPreviewClient() : this.getClient()
      const entries = await client.getEntries({
        ...query,
        content_type: contentType,
        include: DEFAULT_INCLUDE,
        links_to_entry: linkedEntryId,
      })
      return parser(entries)
    } catch (e) {
      return Promise.reject(e)
    }
  },
}

export default appUseContentful
