import { assoc } from 'ramda'

import UseMarketingApi from '@ecm/capability/UseMarketingApi'
import { Account } from '@ecm/data/Account'
import {
  Company,
  isCompany,
  isMarketingError,
  isOrderResponse,
  isStatusCheck,
  MarketingErrorResponse,
  marketingErrorResponse,
  marketingOrderEstimateBody,
  missingParamsError,
  noResponseError,
  OrderResponse,
  serverError,
  StatusCheck,
} from '@ecm/data/Api'
import { Billing } from '@ecm/data/Billing'
import { Cart } from '@ecm/data/Cart'
import { ApiCoupon, apiCoupon, Coupon } from '@ecm/data/Coupon'
import { getOrElse } from '@ecm/data/Maybe'
import { OrderEstimate } from '@ecm/data/Order'
import {
  PaymentIntentRequest,
  PaymentIntentResponse,
  SetupIntentRequest,
  SetupIntentResponse,
  stripeOrderBody,
  StripeOrderRequest,
} from '@ecm/data/Stripe'
import {
  isSaveUserCartResponse,
  isUserCart,
  SaveUserCartRequest,
  SaveUserCartResponse,
  UserCart,
} from '@ecm/data/UserCart'
import { runClientEff } from '@ecm/effect'

import { apiClient } from '../client/ClientApi'

const dispatchReturn = <I, O>(
  responseBody: undefined | MarketingErrorResponse | I,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typGuard: (o: any) => o is I,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor: (o: any) => O
): MarketingErrorResponse | O => {
  if (!responseBody) {
    return noResponseError
  } else if (isMarketingError(responseBody)) {
    return {
      error: {
        code: responseBody.error.code,
        detail: responseBody.error?.detail,
        message: responseBody.error.message,
        type: responseBody.error.type,
      },
    }
  } else if (typGuard(responseBody)) {
    return constructor(responseBody)
  } else {
    return responseBody
  }
}

export const appUseMarketingApi: UseMarketingApi = {
  async checkOrderStatus(uuid?: string) {
    const response = await apiClient.get<StatusCheck | MarketingErrorResponse>(
      `/orders/nxt/status?uuid=${uuid}`
    )
    return dispatchReturn(response.data, isStatusCheck, (o: StatusCheck) => o)
  },

  async createOrUpdateSetupIntent(payload: SetupIntentRequest) {
    const action = payload.setupIntentId ? 'update' : 'create'
    const response = await apiClient.post<SetupIntentResponse>(
      `/stripe/${action}-setup-intent`,
      payload
    )
    if (!response || !response.data) {
      throw Error('An error occurred while invoking the API endpoint')
    }
    if (isMarketingError(response.data)) {
      let detals = ''
      if (response.data.error.detail && response.data.error.detail.length) {
        detals = response.data.error.detail.join(' - ')
      }
      throw Error(
        `[ ${response.data.error.code} / ${response.data.error.type} ] - ${response.data.error.message} (${detals})`
      )
    }
    return response.data
  },

  async createPaymentIntent(payload: PaymentIntentRequest) {
    const response = await apiClient.post<PaymentIntentResponse>(
      `/stripe/create-payment-intent`,
      payload
    )
    if (!response || !response.data) {
      throw Error('An error occurred while invoking the API endpoint')
    }
    if (isMarketingError(response.data)) {
      let detals = ''
      if (response.data.error.detail && response.data.error.detail.length) {
        detals = response.data.error.detail.join(' - ')
      }
      throw Error(
        `[ ${response.data.error.code} / ${response.data.error.type} ] - ${response.data.error.message} (${detals})`
      )
    }
    return response.data
  },

  async findUserCart(externalSub: string) {
    try {
      const response = await apiClient.get<UserCart | MarketingErrorResponse>(
        `/carts/find?external_sub=${externalSub}`
      )
      if (response.status === 404) {
        return null
      }
      return dispatchReturn(response.data, isUserCart, (o: UserCart) => o)
    } catch (e) {
      console.error(e)
    }
    return null
  },

  async getCompanyByEmail(email: string) {
    const response = await apiClient.get<Company | MarketingErrorResponse | undefined>(
      `/hubspot/company/?user_email=${email}`
    )
    return dispatchReturn(response.data, isCompany, (o: Company) => o)
  },

  async getCoupon(coupon: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function instanceOfCoupon(object: any): object is ApiCoupon {
      return 'code' in object
    }
    try {
      const response = await apiClient.get<ApiCoupon | MarketingErrorResponse | undefined>(
        `/coupons/nxt/${coupon}`
      )
      if (!response || response.status === 404) {
        return missingParamsError
      }
      return dispatchReturn<ApiCoupon, Coupon>(response.data, instanceOfCoupon, apiCoupon)
    } catch (e) {
      console.error(e)
    }
    return missingParamsError
  },

  async getOrderEstimate(cart: Cart, billing?: Billing) {
    const response = await apiClient.post<OrderEstimate | MarketingErrorResponse>(
      `/orders/nxt/estimates`,
      assoc('session_id', this.getSessionID(), marketingOrderEstimateBody(cart, billing))
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function instanceOfOrderEstimate(object: any): object is OrderEstimate {
      return 'next_estimate' in object
    }
    return dispatchReturn<OrderEstimate, OrderEstimate>(
      response.data,
      instanceOfOrderEstimate,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (o: any) => o
    )
  },

  getSessionID() {
    return runClientEff(eff => {
      return getOrElse(eff.getStorageItem<string>('scsk'), undefined)
    })
  },

  async saveUserCart(payload: SaveUserCartRequest) {
    const response = await apiClient.post<SaveUserCartResponse>(`/carts/save`, payload)
    return dispatchReturn(response.data, isSaveUserCartResponse, (o: SaveUserCartResponse) => o)
  },

  async submitOrder(
    account: Account,
    billing: Billing,
    cart: Cart,
    setupIntentId?: string,
    paymentIntent?: string
  ) {
    const sessionId = this.getSessionID()

    if (!sessionId || (!setupIntentId && !paymentIntent)) {
      return missingParamsError
    }

    const body: StripeOrderRequest = {
      order: stripeOrderBody(account, billing, cart),
      paymentIntentId: paymentIntent,
      sessionKey: sessionId,
      setupIntentId: setupIntentId,
    }

    const response = await apiClient.post<OrderResponse | MarketingErrorResponse>(
      `/stripe/order`,
      body
    )

    if (!response || !response.data) {
      return serverError
    }
    if (isOrderResponse(response.data)) {
      return response.data
    }
    if (isMarketingError(response.data)) {
      return marketingErrorResponse(
        response.data.error.code,
        response.data.error.message,
        response.data.error.type,
        response.data.error.detail
      )
    } else {
      return noResponseError
    }
  },
}

export default appUseMarketingApi
