import { Entry } from 'contentful'
import map from 'ramda/src/map'

import { Carrier, parseContentfulCarrier } from '@ecm/data/Carrier'
import { ContentfulLocation, Location, location, parseContentfulLocation } from '@ecm/data/Location'
import State, { StateAbbreviation } from '@ecm/data/State'

type AddressCategory = 'popular'
type AddressType = 'pobox' | 'street'
const parseContentfulAddressType = (s: string): AddressType => {
  if (s === 'pobox') {
    return 'pobox'
  } else {
    return 'street'
  }
}

export type AddressSlug = string
export const addressSlug = (s: string): AddressSlug => s

const buildSlug = (s: string): AddressSlug => s.replace(/ /g, '-').toLocaleLowerCase()

type Address_ = {
  addressCategories: AddressCategory[]
  carriers: Carrier[]
  city: string
  isFeatured: boolean
  location: Location
  slug: AddressSlug
  stateAbbreviation: StateAbbreviation
  street1: string
  type: AddressType
  zip: string
}

export type Address = Address_ & {
  __Type: 'address'
  state: string
}

export const address = (a_: Address_): Address => ({
  ...a_,
  __Type: 'address',
  state: State.lookup(a_.stateAbbreviation),
})

export type ContentfulAddress = {
  addressCategories: AddressCategory[]
  carriers: string[]
  city: string
  isFeatured: boolean
  location: ContentfulLocation
  slug: string
  state: string
  street1: string
  type: string
  zip: string
}

export const parseContentfulAddress = (e: Entry<ContentfulAddress>): Address => {
  const fields = e.fields

  return address({
    addressCategories: fields.addressCategories || [],
    carriers: map(parseContentfulCarrier, fields.carriers),
    city: fields.city,
    isFeatured: fields.isFeatured || false,
    location: parseContentfulLocation(fields.location),
    slug: addressSlug(fields.slug),
    stateAbbreviation: State.parseContentful(fields.state),
    street1: fields.street1,
    type: parseContentfulAddressType(fields.type),
    zip: fields.zip,
  })
}

export const addressToString = (a: Address) => {
  const { city, state, street1, zip } = a
  return `${street1}, ${city}, ${state}, ${zip}`
}

export const empty = (): Address => {
  return address({
    addressCategories: [],
    carriers: ['ups'],
    city: '',
    isFeatured: false,
    location: location({ lat: 0, lng: 0 }),
    slug: '',
    stateAbbreviation: 'OR',
    street1: '',
    type: 'street',
    zip: '',
  })
}

export default {
  buildSlug: buildSlug,
  empty: empty,
  parseContentful: parseContentfulAddress,
  toString: addressToString,
}

export const removeDuplicateObjectsFromArray = (addresses: FlatArray<Address[][], 1>[]) => {
  //This code does a deep equals on an array of addresses or an array of any object to find "duplicates" and removes them.
  //The easiest way to do that for an object of arbitrary depth is to turn it into a string and do a value comparison
  return addresses.filter(
    (v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
  )
}
