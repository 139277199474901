import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import appRead from '@ecm/effect/app/Read'
import { getCognitoToken } from '@ecm/effect/client/UserAuth'

const baseApiUrl = (() => appRead.ask().api.domain)()

const getAuthorizationHeader = async (config: AxiosRequestConfig) => {
  try {
    const token = await getCognitoToken()
    config.headers.Authorization = `Bearer ${token}`
  } catch (e) {
    console.log('apiClient.interceptors#e', e)
    throw e
  }
  return config
}

export const apiClient: AxiosInstance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(async function (config) {
  return getAuthorizationHeader(config)
})

apiClient.interceptors.response.use(
  response => response,
  async error => {
    return await Promise.reject(error.response ? error.response : error)
  }
)
