import { Billing } from '@ecm/data/Billing'
import { Cart } from '@ecm/data/Cart'

export type HttpResponse<T> = Response & { parsedBody?: T }

export type ApiEnv = {
  api: {
    domain: string
  }
}

export type Body = {
  [key: string]: string
}

export type MarketingBillingBody = {
  billing_first_name: string
  billing_last_name: string
  billing_address_1: string
  billing_locality: string
  billing_postal_code: string
  billing_region: string
  billing_country: string
  phone: string
}

export const marketingOrderEstimateBody = (
  cart: Cart,
  billing?: Billing
): MarketingOrderEstimateBody => {
  const addressIds = cart.addresses.map(a => a.slug)
  return {
    address_ids: addressIds,
    billing_address_1: billing?.billingAddress, //address info for taxes - optional; required for tax calcs
    billing_country: billing?.countryCode,
    billing_locality: billing?.city,
    billing_postal_code: billing?.zipCode,
    billing_region: billing?.stateCode,
    coupon_code: cart.coupon?.code,
    plan_handle: cart.plan.handle,
  }
}

export type MarketingOrderEstimateBody = {
  plan_handle: string
  address_ids: string[]
  coupon_code?: string
  billing_address_1?: string
  billing_address_2?: string
  billing_country?: string
  billing_locality?: string
  billing_postal_code?: string
  billing_region?: string
}

export const isMarketingError = (object: any): object is MarketingErrorResponse => {
  return 'error' in object
}

export const marketingErrorResponse = (
  code: number,
  message: string,
  type: string,
  detail?: string | string[]
): MarketingErrorResponse => {
  return {
    error: {
      code: code,
      detail: detail ? (Array.isArray(detail) ? detail : [detail]) : undefined,
      message: message,
      type: type,
    },
  }
}

export type MarketingErrorResponse = {
  error: {
    message: string
    detail?: string[]
    type: string
    code: number
  }
}

export const noResponseError: MarketingErrorResponse = {
  error: {
    code: 0,
    detail: ['Please contact sales'],
    message: 'Unable to form a connection ',
    type: 'failed call',
  },
}

export const missingParamsError: MarketingErrorResponse = {
  error: {
    code: 0,
    detail: ['Please try again or contact our support.'],
    message: 'Missing or wrong parameters. Unable to process form.',
    type: 'failed validation',
  },
}

export const serverError: MarketingErrorResponse = {
  error: {
    code: 500,
    detail: ['An error occurred. Please try again or contact our support.'],
    message: 'Server error!',
    type: 'failed call',
  },
}

export const isStatusCheck = (obj: any): obj is StatusCheck => {
  return 'complete' in obj
}

export type StatusCheck = {
  complete: boolean
}

export type OrderResponse = {
  uuid: string
  remote_subscription_id: string
}

export const isOrderResponse = (obj: any): obj is OrderResponse => {
  return 'uuid' in obj
}

export type Company = {
  name: string
}

export const isCompany = (object: Company | MarketingErrorResponse): object is Company => {
  return 'name' in object
}
