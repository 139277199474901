import { Document } from '@contentful/rich-text-types'
import { Entry } from 'contentful'

export type SnippetSlug = string
export const snippetSlug = (s: string): SnippetSlug => s

type Snippet_ = {
  content: Document
  slug: string
}
export type Snippet = Snippet_ & { __Type: 'snippet' }

export const snippet = (a_: Snippet_): Snippet => ({
  ...a_,
  __Type: 'snippet',
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ContentfulSnippet = {
  content: Document
  slug: string
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const parseContentfulSnippet = (e: Entry<ContentfulSnippet>): Snippet => {
  const { content, slug } = e.fields

  return snippet({
    content: content,
    slug: slug,
  })
}

export default { parseContentful: parseContentfulSnippet }
