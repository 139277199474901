import React from 'react'
import { useTheme } from '@mui/material'
import Image, { ImageLoaderProps, ImageProps } from 'next/image'

import useWindowSize from '@ecm/ui/hook/UseWindowSize'

export enum ImageType {
  BlogGrid,
  BlogResponsive,
  CaseStudyHero,
  NonResponsive,
}

type ContentfulImageProps = Omit<ImageProps, 'loader' | 'sizes'> & { imageType: ImageType }

const loader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&q=${quality || 80}`
}

const ContentfulImage = (props: ContentfulImageProps) => {
  const { imageType, ...otherProps } = props
  const theme = useTheme()
  const [windowWidth] = useWindowSize()

  const breakpoints = theme.breakpoints.values

  const sizes = {
    [ImageType.BlogGrid]: `(max-width: ${breakpoints.sm}px) calc(100vw - 32px), (max-width: ${breakpoints.md}px) calc(50vw - 112px), (max-width: ${breakpoints.lg}px) calc(50vw - 144px), (max-width: ${breakpoints.xl}px) calc(33vw - 128px), calc(100vw - ${windowWidth}px + 427px)`,
    [ImageType.BlogResponsive]: `(max-width: ${breakpoints.sm}px) calc(100vw - 32px), (max-width: ${breakpoints.md}px) calc(100vw - 48px), (max-width: ${breakpoints.lg}px) calc(100vw - 376px), (max-width: 1406px) calc(100vw - 555px), calc(100vw - ${windowWidth}px + 850px)`,
    [ImageType.CaseStudyHero]: `(max-width: ${breakpoints.sm}) calc(100vw - 32px), (max-width: ${breakpoints.md}) calc(50vw - 80px), (max-width: ${breakpoints.lg}) calc(50vw - 112px), (max-width: ${breakpoints.xl}) calc(50vw - 160px), calc(100vw - ${windowWidth}px + 688px)`,
    [ImageType.NonResponsive]: '',
  }

  return <Image loader={loader} {...otherProps} sizes={sizes[imageType]} />
}

export default ContentfulImage
